import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";

interface Props {
    children: ReactElement;
}

const Wrapper: React.FC<Props> = ({ children }) => {
    return (
        <div style={{ width: "100%", overflow: "hidden", maxWidth: isMobile ? "" : "700px", margin: "0 auto" }}>
            {children}
        </div>
    );
};

export default Wrapper;