import React from "react";

export default function RotationRightSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_538_2337)">
                <path d="M17.25 3V7.5H12.75" stroke="url(#paint0_linear_538_2337)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.3674 11.25C14.8799 12.6299 13.9571 13.8141 12.738 14.6239C11.519 15.4337 10.0698 15.8254 8.60873 15.74C7.1477 15.6545 5.75403 15.0965 4.63773 14.15C3.52143 13.2036 2.74298 11.9199 2.41968 10.4926C2.09639 9.06518 2.24577 7.5714 2.84531 6.23631C3.44485 4.90122 4.46206 3.79716 5.74368 3.09049C7.02529 2.38382 8.50186 2.11284 9.95088 2.31837C11.3999 2.5239 12.7429 3.19481 13.7774 4.23001L17.2499 7.50001" stroke="url(#paint1_linear_538_2337)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_538_2337" x1="12.75" y1="3" x2="17.25" y2="7.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0033" />
                    <stop offset="1" stopColor="#0F00FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_538_2337" x1="2.25293" y1="2.25146" x2="15.6786" y2="17.1659" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0033" />
                    <stop offset="1" stopColor="#0F00FF" />
                </linearGradient>
                <clipPath id="clip0_538_2337">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}