import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

let getFirebaseConfig;
const devUrls = [
    "localhost",
    "admin-synk-development-6a2a7.web.app",
    "synk-development.web.app",
    "admin-synk-development-6a2a7.firebaseapp.com",
    "synk-development.firebaseapp.com",
    "10.0.0.106",
];

// use staging credentials if localhost or staging site
if (devUrls.includes(window.location.hostname)) {
    // STAGING
    getFirebaseConfig = {
        apiKey: "AIzaSyBzsGwMDulVmJ_QwVTzqklfFf2ly313Lhs",
        authDomain: "synk-development.firebaseapp.com",
        projectId: "synk-development",
        storageBucket: "synk-development.appspot.com",
        messagingSenderId: "1018537217404",
        appId: "1:1018537217404:web:7ffc04834ba45ef2d228f9",
        measurementId: "G-N4WX8FYYKH",
    };
} else {
    // PROD
    getFirebaseConfig = {
        apiKey: "AIzaSyCqxnddbEbG580IoVxnK0whaFuhARL_msk",
        authDomain: "synk-edae9.firebaseapp.com",
        projectId: "synk-edae9",
        storageBucket: "synk-edae9.appspot.com",
        messagingSenderId: "800195129760",
        appId: "1:800195129760:web:953376e5e25f8d245d74c5",
        measurementId: "G-MFR54WPXW4",
    };
}

export const firebaseConfig = getFirebaseConfig;
const firebaseApp = firebase.initializeApp(firebaseConfig);

const storage = firebaseApp.storage();
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const functions = firebaseApp.functions();

export { db, auth, storage, firebaseApp, firebase, functions, devUrls };
