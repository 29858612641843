import React, { useState } from "react";
import styles from "./styles/loader.module.scss";

type Props = {
	timer: number,
	counter: number,
	setCounter: (value: number) => void;
};

const Loader: React.FC<Props> = ({ timer, counter, setCounter }) => {
	const [percent, setPercent] = useState<number>(0);

	const interval = setInterval(() => {

		if (percent <= 99) {
			setPercent(percent + (100 / timer));
			if (counter > 0) {
				setCounter(counter - 1);
			}
		}

		if (counter === 0) {
			setCounter(-1);
		}
		
		clearInterval(interval);

	}, 1000);

	let radius = 52;
	let circumference: number = radius * 2 * Math.PI;
	let offset = circumference - percent / 100 * circumference;

	return (
		<div className={styles.loader}>
			<div>
				<svg
					className={styles.progressRing}
					height="120"
					width="120"
				>
					{/* Main circle */}
					<circle
						style={{
							strokeDasharray: `${circumference} ${circumference}`,
							strokeDashoffset: offset
						}}
						className={styles.circle}
						strokeWidth="7"
						stroke="white"
						rx="15"
						fill="transparent"
						r={radius}
						cx="60"
						cy="60"
					/>
					{/* Background circle */}
					<circle
						className={styles.circleBg}
						style={{ display: 'none' }}
						strokeWidth="4"
						stroke="rgba(255, 255, 255, 0.4)"
						fill="transparent"
						r={radius}
						cx="60"
						cy="60"
					/>
				</svg>
			</div>
			<span className={styles.timer}>{counter}</span>
		</div>
	);
};

export default Loader;