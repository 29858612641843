import { Dispatch } from "react";
import { Event } from "interfaces";
import { GridType } from "modules/edit-photo/grids/interfaces";
import { ActionMap } from "./data/action-map";

export type AppDispatcher = Dispatch<ActionList>;

/**
 * List of actions that modify app
 */
export enum AppAction {
    Submit = "SUBMIT",
    SetPhone = "SET_PHONE",
    EditPhoto = "EDIT_PHOTO",
    SetMosaic = "SET_MOSAIC",
    SetSelectedGrid = "SET_SELECTED_GRID",
    SetLayer = "SET_LAYER",
    SetFeatureLayer = "SET_FEATURE_LAYER",
    SetEvent = "SET_EVENT",
    SetFinish = "SET_FINISH",
    SetPermission = "SET_PERMISSION",
    SetApproval = "SET_APPROVAL",
    SetDonationModal = "SET_DONATION_MODAL",
    SetRetakeCount = "SET_RETAKE_COUNT",
    SetInvitationModal = "SET_INVITATION_MODAL",
    GetGridImages = "GET_GRID_IMAGES",
    SetLoading = "SET_LOADING",
}

/**
 * Define action parameter for actions
 */
type AppPayload = {
    [AppAction.Submit]: {
        currentImage: string;
        editedPicture: string;
    };
    [AppAction.SetPhone]: {
        userNumber: string | number;
        user: any;
    };
    [AppAction.EditPhoto]: {
        editedPicture: string;
        renderPicture: string;
    };
    [AppAction.SetMosaic]: {
        picture: any; // Update this
        editedPicture: string;
    };
    [AppAction.SetSelectedGrid]: {
        selectedGrid: GridType;
        disableShareButton: boolean;
    };
    [AppAction.SetLayer]: {
        layer: string;
    };
    [AppAction.SetFeatureLayer]: {
        featureLayer: string;
    };
    [AppAction.SetEvent]: {
        event: Event;
        selectedGrid: GridType;
    };
    [AppAction.SetFinish]: {};
    [AppAction.SetPermission]: {
        privacyPermission: boolean;
    };
    [AppAction.SetApproval]: {
        picture: any;
    };
    [AppAction.SetDonationModal]: {
        donationModal: boolean;
    };
    [AppAction.SetRetakeCount]: {
        retakeCount: number;
    };
    [AppAction.SetInvitationModal]: {
        invitationModal: boolean;
    };
    [AppAction.GetGridImages]: {};
    [AppAction.SetLoading]: {
        loading: boolean;
    };
};

export type ActionList = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];
