import { Grid } from "@material-ui/core";
import React from "react";
import { Ticker } from "interfaces";
import styles from "./styles/ticker-item.module.scss";

interface Props {
    ticker: Ticker;
    paidEvent?: boolean;
    tickerBuyerDescription?: string;
}

const TickerItem: React.FC<Props> = ({ ticker, paidEvent, tickerBuyerDescription }) => {
    return (
        <div style={{ marginRight: "1em" }}>
            <Grid container justifyContent="center" spacing={1} className={styles.item}>
                {ticker.showName &&
                    <Grid item style={{ whiteSpace: "nowrap" }}>
                        {ticker.firstName} {(tickerBuyerDescription && ticker.price > 0) ? tickerBuyerDescription : ""}
                    </Grid>
                }
                {(ticker.showName && ticker.showPrice && paidEvent && ticker.price > 0) &&
                    <Grid item>
                        ${(ticker.price / 100).toFixed(2)}
                    </Grid>
                }
            </Grid>
        </div>
    );
};

export default TickerItem;