import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { db } from "firebase-app/config";
import { Question } from "interfaces";
import FaqItem from "./faq-item";
import { LoadIcon } from "modules/ui";

const Faq = () => {
    const [items, setItems] = useState<Question[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getQuestions = async () => {
        setLoading(true);

        db.collection("questions").get().then((querySnapshot) => {

            querySnapshot.docs.map(doc => {

                setItems((prevState) => [...prevState, doc.data() as Question]);
                
                return undefined;
            });
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        getQuestions();
    }, []);

    return (loading ?
        <LoadIcon centered /> :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={{ textAlign: "center" }} variant="h6">Frequently Asked Questions</Typography>
            </Grid>
            <Grid item xs={12}>
                {items && items.map((item, i) => <FaqItem key={i} item={item} />)}
            </Grid>
        </Grid>
    );
};

export default Faq;