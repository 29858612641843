import React, { useContext, createContext, useReducer, Dispatch} from 'react';
import { State, InitialState, reducer, StateActions } from '../reducers/reducer'


interface ContextProps {
  state: State;
  dispatch: Dispatch<StateActions>;
}

// creating context with initial state
const Context = createContext<ContextProps>({
  state: InitialState, 
  dispatch: () => {}
})


const {Provider, Consumer} = Context

const StateContextProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(reducer, InitialState)

  return (
    <Provider value={{state, dispatch}}>
      {children}
    </Provider>
  )
}

const StateContext = () => useContext(Context)

export {StateContextProvider, StateContext, Consumer as StateContextConsumer}