import { Dialog, DialogContent, Typography } from "@material-ui/core";
import React from "react";
import HorizontalLoader from "../horizontal-loader";

interface Props {
    loading: boolean;
    error: string;
    verifiedUser: boolean;
}

const LoadingModal: React.FC<Props> = ({ loading, error, verifiedUser }) => {
    return (
        <Dialog open={(loading && verifiedUser)}>
            <DialogContent>
                <div style={{ textAlign: "center" }}>
                    <h3>Finishing Upload</h3>
                    <Typography variant="subtitle1">We Are Uploading your Image.</Typography>
                    {error && <h3 style={{ color: "crimson" }}>{error}</h3>}
                    <HorizontalLoader />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LoadingModal;