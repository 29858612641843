import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Ticker as TickerInterface, TickerBlob } from "interfaces";
import { TickerItem } from "./components";
import Ticker from "react-ticker";
import styles from "./styles/screen-ticker.module.scss";

interface Props {
    ticker: TickerBlob;
    isPaidEvent?: boolean;
}

const ScreenTicker: React.FC<Props> = ({ ticker, isPaidEvent }) => {
    const [tickerState, setTickerState] = useState<TickerInterface[]>([]);
    const [count, setCount] = useState<number>(0);

    const tickerArray = useMemo(() => {
        return ticker.tickerArray?.filter((item) => item.showNone !== true) ?? [];
    }, [ticker.tickerArray]);

    useEffect(() => {
        setTickerState(tickerArray);
    }, [tickerArray, setTickerState]);

    return (
        <div className={styles.tickerBorder}>
            {(tickerState.length > 0 || ticker.description) &&
                <div>
                    <Ticker
                        speed={Number(ticker.speed) || 3}
                        onNext={() => {
                            if (count >= tickerArray.length - 1) {
                                setCount(0);
                            } else {
                                setCount(prev => prev + 1);
                            }
                        }}
                    >
                        {({ index }) =>
                            <Grid container key={index} style={{ flexWrap: "nowrap" }}>
                                {(tickerState.length === 0 || (ticker.description && index % 5 === 0)) &&
                                    <Grid item>
                                        <div style={{ marginRight: "1em", whiteSpace: "nowrap" }}>
                                            {ticker.description}
                                        </div>
                                    </Grid>
                                }
                                {tickerState.length > 0 &&
                                    <Grid item>
                                        <TickerItem tickerBuyerDescription={ticker.buyerDescription} ticker={tickerArray[count]} paidEvent={isPaidEvent} />
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Ticker>
                </div>
            }
        </div>
    );
};

export default ScreenTicker;