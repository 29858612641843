import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup } from "@material-ui/core";
import { StateContext } from "context/context";
import { db, firebase } from "firebase-app/config";
import { useParams } from "react-router";
import { Ticker } from "interfaces";

interface Input {
    firstName: string;
    price?: number;
}

interface Checkboxes {
    showName: boolean;
    showPrice: boolean;
    showNone: boolean;
}

const ScreenTickerModal: React.FC = () => {
    const { state } = StateContext();

    let userTickerOption: Ticker | undefined = undefined;

    if (state.user) {
        userTickerOption = state.event.ticker?.filter((item) => item.userId === state.user.uid)[0];
    }

    const [open, setOpen] = useState<boolean>((userTickerOption === undefined));

    const [checkboxes, setCheckboxes] = useState<Checkboxes>({
        showName: false,
        showPrice: false,
        showNone: false,
    });
    const [inputs, setInputs] = useState<Input>({
        firstName: "",
        price: 0,
    });

    const { id } = useParams<any>();

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked });

    const handleSubmit = (e: any) => {
        e.preventDefault();

        try {
            const updateTicker = db.collection("events").doc(id);

            updateTicker.update({
                ticker: firebase.firestore.FieldValue.arrayUnion({
                    userId: state.user.uid,
                    ...inputs,
                    ...checkboxes
                })
            });

            setOpen(false);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPayments();
    }, []);

    const getPayments = () => {
        const payment = db.collection("customers").doc(state.user?.uid).collection("payments").where("status", "==", "succeeded").get();

        db.collection("products")
            .where("active", "==", true)
            .where("metadata.eventId", "==", state.event.id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (productDoc: any) {
                    const priceSnap = await productDoc.ref.collection("prices").get();

                    await priceSnap.docs.forEach((price: any) => {

                        payment.then(async (querySnapshot) => {
                            querySnapshot.forEach((doc) => {

                                const payment = doc.data();

                                if (payment.items[0].price.id === price.id) {

                                    const purchaseName = payment.charges.data[0].billing_details.name.split(" ") ?? [];

                                    if (purchaseName.length > 0)
                                        setInputs({
                                            firstName: purchaseName[0],
                                            price: payment.amount
                                        });
                                }
                            });
                        });
                    });
                });
            })
            .catch((error) => error.console.log(error));
    };

    return (
        <Dialog open={open}>
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{ paddingBottom: 0 }}>
                    Join Our Ticker
                    </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Thanks, {inputs.firstName} for joining this event. Would you like to add your name to our ticker?
                    </DialogContentText>                 

                    <Box mt={2}>
                        <FormGroup>
                            <FormControlLabel label="Show Name" control={<Checkbox color="primary" name="showName" onChange={handleCheckboxChange} />} />
                            {state.event.isPaidEvent &&
                                <FormControlLabel label="Show Price" control={<Checkbox color="primary" name="showPrice" onChange={handleCheckboxChange} />} />
                            }
                            <FormControlLabel label="Show None" control={<Checkbox color="primary" name="showNone" onChange={handleCheckboxChange} />} />
                        </FormGroup>
                    </Box>
                </DialogContent>
                <DialogActions style={{ marginBottom: "1em" }}>
                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ScreenTickerModal;