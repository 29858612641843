export const setFrameLevel = (
    start: number,
    reverse: boolean,
    sequence: number,
): [number, boolean] => {
    if (start >= 1) reverse = false;
    if (reverse) start += sequence;
    if (!reverse) start -= sequence;
    if (start <= 0) {
        reverse = true;
        start += sequence + sequence;
    }

    if (start > 1) start = 1;
    if (start < 0) start = 0;

    return [start, reverse];
};
