import "styles/style.scss";
import React, { lazy, Suspense, useEffect } from "react";
import { createTheme, ThemeProvider, } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { ProtectedRoute } from "helpers/routes";
import { StateContext } from "context/context";
import { Event } from "modules/event";
import { Photo } from "modules/photo";
import { Home } from "modules/home";
import { Contact, Events, Faq, TermsAndConditions } from "modules/events";
import { EditPhoto } from "modules/edit-photo";
import { Share } from "modules/share";
import { theme as appTheme } from "styles/theme";
import { auth, db } from "firebase-app/config";
import { AppAction } from "reducers/reducer-action";
import { LoadIcon, Wrapper } from "modules/ui";

const ThousandGrid = lazy(() => import("modules/section-grid/thousand-grid"));
const DynamicGrid = lazy(() => import("modules/section-grid/dynamic-grid"));
const MassiveGrid = lazy(() => import("modules/massive-grid/massive-grid"));

const theme = createTheme(appTheme);

function App() {
	const { state, dispatch } = StateContext();

	const { userNumber, picture: { currentImage } } = state;

	useEffect(() => {
		if (!state.user) {
			dispatch({
				type: AppAction.SetPhone,
				payload: {
					...state,
					userNumber: state.userNumber,
					user: auth.currentUser
				}
			});
		}

		if (state.user) {
			db.collection("users").doc(state.user.uid).set({});
		}

		if (state?.user && state.user.user) {
			if (state.user.user.phoneNumber === null) {
				localStorage.removeItem("phoneNumber");
				localStorage.removeItem("synk-edae9-userData");
				auth.signOut();
			}
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Suspense fallback={<LoadIcon centered />}>
					<Switch>
						<Route path={ROUTES.CONTACT_AND_SUPPORT}>
							<Wrapper>
								<Contact />
							</Wrapper>
						</Route>
						<Route path={ROUTES.TERMS_AND_CONDITIONS}>
							<Wrapper>
								<TermsAndConditions />
							</Wrapper>
						</Route>
						<Route path={ROUTES.FAQ}>
							<Wrapper>
								<Faq />
							</Wrapper>
						</Route>
						<Route path={ROUTES.PHOTO}>
							<Wrapper>
								<Photo />
							</Wrapper>
						</Route>
						<ProtectedRoute user={userNumber} image={currentImage.url} path={ROUTES.SHARE}>
							<Wrapper>
								<Share />
							</Wrapper>
						</ProtectedRoute>
						<ProtectedRoute user={userNumber} image={currentImage.url} path={ROUTES.EDIT_PHOTO}>
							<Wrapper>
								<EditPhoto />
							</Wrapper>
						</ProtectedRoute>
						<Route exact path={`${ROUTES.EVENT}/:id`}>
							<Wrapper>
								<Event />
							</Wrapper>
						</Route>
						<Route path={`${ROUTES.EVENT}/:id${ROUTES.GRID_VIEW}`}>
							<ThousandGrid />
						</Route>
						<Route path={`${ROUTES.EVENT}/:id${ROUTES.DYNAMIC_VIEW}`}>
							<DynamicGrid />
						</Route>

						<Route path={`${ROUTES.MASSIVE}/:id`}>
							<MassiveGrid />
						</Route>

						<Route path={ROUTES.EVENTS}>
							<Wrapper>
								<Events />
							</Wrapper>
						</Route>
						<Route exact path="/">
							<Wrapper>
								<Home />
							</Wrapper>
						</Route>
					</Switch>
				</Suspense>
			</Router>
		</ThemeProvider>
	);
}

export default App;
