import { Button, MenuItem, Menu } from "@material-ui/core";
import React, { useState } from "react";
import { IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MenuArray } from "./data";
import styles from "./styles/menu.module.scss";

const MainMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                style={{ minWidth: 0, padding: 0, fontSize: 20 }}
                id="main-button"
                aria-controls={open ? "main-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                aria-label="Main Menu"
            >
                <IoMenu />
            </Button>
            <Menu
                className={styles.menu}
                id="main-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "main-button",
                }}
            >
                {
                    MenuArray.map((item, index) =>
                        <MenuItem
                            key={index}
                            to={item.route}
                            component={Link}
                            onClick={handleClose}
                        >
                            {item.label}
                        </MenuItem>)
                }
            </Menu>
        </div>
    );
};


export default MainMenu;