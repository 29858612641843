import { Typography } from "@material-ui/core";
import React from "react";
import { DonationTag } from "../svg";
import styles from "./styles/header-tag.module.scss";

interface Props {
    label: string;
}

const HeaderTag: React.FC<Props> = (props) => {

    const { label } = props;

    return (
        <div className={styles.banner}>
            <div className={styles.left}>
                <DonationTag />
            </div>
            <div className={styles.middle}>
                <DonationTag />
            </div>
            <div className={styles.right}>
                <DonationTag />
            </div>
            <Typography variant="h6">{label}</Typography>
        </div>
    );
};

export default HeaderTag;