import { db, storage } from "firebase-app/config";
import { dbLogError } from "hooks/dbLogError";
import { Event } from "interfaces";
import { GridImage, GridType } from "modules/edit-photo/grids/interfaces";
import { getDownloadImage } from "modules/ui";
import { AppAction } from "./reducer-action";

const localPhoneNumber: any = localStorage.getItem("phoneNumber");
const localPrivacyPerms: any = localStorage.getItem("userPrivacy");
const localUserData: any = localStorage.getItem("synk-edae9-userData");
const localEvent: any = localStorage.getItem("synk-edae9-currentEvent");
// const localCameraPerms: any = localStorage.get("synk-edae9-cameraPerm")

const userData = JSON.parse(localUserData);
const userPrivacy = JSON.parse(localPrivacyPerms);
const currentEvent = JSON.parse(localEvent);

// App interface
export interface State {
    userNumber: string | number;
    user: any;
    picture: {
        currentImage: {
            id: string | undefined;
            url: string;
            isApproved: null | boolean;
            phoneNumber?: number;
        };
        currentMosaic: string;
    };
    editedPicture: string;
    layer: string;
    featureLayer?: string;
    event: Event;
    privacyPermission: boolean;
    // cameraPermission: boolean;
    selectedGrid?: GridType;
    donationModal: boolean;
    invitationModal: boolean;
    retakeCount: number;
    gridImages: GridImage[];
    loading: boolean;
}

export type StateActions = {
    type: AppAction;
    payload: State;
};

const setLocalStorage = (number: number | string) => {
    localStorage.setItem("phoneNumber", number.toString());
};

const setLocalUser = (user: any) => {
    localStorage.setItem("synk-edae9-userData", JSON.stringify(user));
};

const setLocalPerms = (permission: boolean) => {
    localStorage.setItem("userPrivacy", JSON.stringify(permission));
};

export const InitialState: State = {
    userNumber: localPhoneNumber || "",
    user: userData,
    picture: {
        currentImage: {
            id: "",
            url: "",
            isApproved: null,
            phoneNumber: undefined,
        },
        currentMosaic: "",
    },
    editedPicture: "",
    layer: "",
    featureLayer: undefined,
    event: currentEvent || {
        title: "",
        eventId: "",
        startDate: "",
        cameraTimer: 10,
        overlays: [],
        donation: true,
        threshold: 6000,
    },
    privacyPermission: userPrivacy || false,
    selectedGrid: undefined,
    // cameraPermission: false,
    donationModal: false,
    invitationModal: true,
    retakeCount: 3,
    gridImages: [],
    loading: false,
};

let iNum = 110;
let getFeature = true;

export const reducer = (state: State, action: StateActions) => {
    switch (action.type) {
        case AppAction.Submit:
            return (state = {
                ...state,
                picture: {
                    ...state.picture,
                    currentImage: { ...action.payload.picture.currentImage },
                },
                editedPicture: action.payload.editedPicture,
            });
        case AppAction.SetPhone:
            setLocalStorage(action.payload.userNumber);
            setLocalUser(action.payload.user);

            return (state = {
                ...state,
                userNumber: action.payload.userNumber,
                user: action.payload.user,
            });
        case AppAction.EditPhoto:
            return (state = {
                ...state,
                editedPicture: action.payload.editedPicture,
            });
        case AppAction.SetMosaic:
            return (state = {
                ...state,
                picture: {
                    ...state.picture,
                    currentMosaic: action.payload.picture.currentMosaic,
                },
                editedPicture: action.payload.editedPicture,
            });
        case AppAction.SetSelectedGrid:
            return (state = {
                ...state,
                selectedGrid: action.payload.selectedGrid,
            });
        case AppAction.SetLayer:
            return (state = {
                ...state,
                layer: action.payload.layer,
            });
        case AppAction.SetFeatureLayer:
            return (state = {
                ...state,
                featureLayer: action.payload.featureLayer,
            });
        case AppAction.SetEvent:
            localStorage.setItem(
                "synk-edae9-currentEvent",
                JSON.stringify(action.payload.event),
            );

            return (state = {
                ...state,
                event: action.payload.event,
                selectedGrid: action.payload.selectedGrid,
                featureLayer: undefined,
            });
        case AppAction.SetFinish:
            localStorage.removeItem("synk-edae9-currentEvent");

            return (state = {
                ...state,
                picture: {
                    currentImage: { url: "", id: "", isApproved: null },
                    currentMosaic: "",
                },
                editedPicture: "",
                donationModal: true,
                invitationModal: true,
                featureLayer: undefined,
                gridImages: [],
            });
        case AppAction.SetPermission:
            setLocalPerms(action.payload.privacyPermission);

            return (state = {
                ...state,
                privacyPermission: action.payload.privacyPermission,
            });

        // not currently being used but may need to be incorporated
        case AppAction.SetApproval:
            return (state = {
                ...state,
                picture: {
                    ...state.picture,
                    currentImage: {
                        ...state.picture.currentImage,
                        // isApproved: action.payload.isApproved
                    },
                },
            });
        case AppAction.SetDonationModal:
            return (state = {
                ...state,
                donationModal: action.payload.donationModal,
            });
        case AppAction.SetInvitationModal:
            return (state = {
                ...state,
                invitationModal: action.payload.invitationModal,
            });
        case AppAction.SetRetakeCount:
            const currentCount = state.retakeCount;

            return (state = {
                ...state,
                retakeCount: currentCount - 1,
            });
        case AppAction.GetGridImages:
            const userImage = state.picture?.currentImage;

            const randomImageUrls: GridImage[] = [];

            const getRandomImages = async () => {
                try {
                    let imagesRemaining: number = iNum - 1;

                    //Push main user image into index 0
                    randomImageUrls.push({
                        image: userImage.url.toString(),
                        id: state.user?.uid,
                        type: "original",
                    });

                    // Above
                    imagesRemaining = await imageQuery(
                        true,
                        imagesRemaining,
                        true,
                        getFeature,
                    );

                    // Below
                    if (imagesRemaining > 0) {
                        imagesRemaining = await imageQuery(
                            false,
                            imagesRemaining,
                            false,
                            false,
                        );
                    }

                    // fill remaining with already existing images if not enough exist
                    if (imagesRemaining !== 0) {
                        for (let i = 0; i < imagesRemaining; i++) {
                            if (randomImageUrls[i].type !== "feature") {
                                const imageFill: GridImage = {
                                    ...randomImageUrls[i],
                                    type: "normal",
                                };

                                // Prevent over populating Grid
                                if (randomImageUrls.length !== iNum) {
                                    randomImageUrls.push(imageFill);
                                }
                            }
                        }
                    }

                    let cleanArray = randomImageUrls;

                    // Cleans array if too many images
                    if (cleanArray.length > iNum) {
                        cleanArray = randomImageUrls.slice(0, iNum);
                    }

                    // setGrid(cleanArray);
                } catch (err: any) {
                    console.error("oops, something went wrong ", err);
                    await dbLogError(
                        err,
                        "error getting random images via Grid Feature Large.tsx",
                    );
                }
            };

            const imageQuery = async (
                firstLoop: boolean,
                imagesRemaining: number,
                isAbove: boolean,
                getFeature?: boolean,
            ) => {
                const ref = db
                    .collection("events")
                    .doc(state.event.id)
                    .collection("eventImages");

                if (getFeature) {
                    if (
                        state.event.featurePersonNumberOne ||
                        state.event.featurePersonImage?.url
                    ) {
                        if (state.event.featurePersonImage?.url) {
                            const url = await storage
                                .ref(state.event.featurePersonImage?.path)
                                .getDownloadURL();

                            await getDownloadImage(url).then((response) => {
                                const url = response as string;

                                randomImageUrls.push({
                                    image: url,
                                    id: "feature",
                                    type: "feature",
                                });
                            });
                        } else {
                            const ref = db
                                .collection("events")
                                .doc(state.event.id)
                                .collection("eventImages");
                            const featureImage = await ref
                                .where(
                                    "phoneNumber",
                                    "==",
                                    state.event.featurePersonNumberOne,
                                )
                                .limit(1)
                                .get();

                            featureImage.forEach(async (doc) => {
                                const image = doc.data();

                                const url = await storage
                                    .ref(image.srcPath)
                                    .getDownloadURL();

                                await getDownloadImage(url).then((response) => {
                                    const url = response as string;

                                    randomImageUrls.push({
                                        image: url,
                                        id: "feature",
                                        type: "feature",
                                    });
                                });
                            });
                        }
                    }
                }

                // temporary array to hold each image doc containing id, path, and url
                const tempImgs: any[] = [];

                // Grabs Images from seeded folder.
                if (
                    firstLoop &&
                    ((state.event.photo?.picNow !== undefined &&
                        state.event.photo?.picNow) ||
                        (state.event.photo?.seededImages !== undefined &&
                            state.event.photo?.seededImages))
                ) {
                    const seededImages = db.collection("seededImages");

                    const sizeOfDB = (await ref.get()).size;

                    if (sizeOfDB < iNum) {
                        let seededArray = await seededImages
                            .limit(iNum - sizeOfDB)
                            .get();

                        seededArray.forEach((doc) => {
                            tempImgs.push(doc.data());
                        });
                    }
                }

                let randomImageDocs = await ref
                    .where("id", isAbove ? "<" : ">", userImage.id)
                    .where("isApproved", "==", true)
                    .orderBy("id", isAbove ? "desc" : "asc")
                    .limit(imagesRemaining)
                    .get();

                // loop over snapshot and add each doc data to the tempImgs arr
                randomImageDocs.forEach((doc) => {
                    tempImgs.push(doc.data());
                });

                // get base64 version of images from url and use Promise to WAIT for them to complete before moving on
                const base64Images: string[] = (await Promise.all(
                    tempImgs.map(async (img) => {
                        const url = await storage
                            .ref(img.thumbPath)
                            .getDownloadURL();
                        return getDownloadImage(url);
                    }),
                )) as string[];

                // combine base64 images back with their id into a new array of image objects
                const arrImgObjects = tempImgs.map(
                    (img, index) =>
                        ({
                            image: base64Images[index],
                            id: img.id,
                            type: "normal",
                        } as GridImage),
                );

                // push this new array of objects to the preexisting randomImageUrls array
                randomImageUrls.push(...arrImgObjects);
                return imagesRemaining - randomImageDocs.size;
            };

            getRandomImages();

            return (state = {
                ...state,
                gridImages: randomImageUrls,
            });
        case AppAction.SetLoading:
            return (state = { ...state, loading: action.payload.loading });
        default:
            return state;
    }
};
