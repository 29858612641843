import { Button as MaterialButton, ButtonProps } from "@material-ui/core";
import styles from "./styles/button.module.scss";
import clsx from "clsx";
import React from "react";
import { omit } from "lodash";

interface Props extends ButtonProps {
    btnType?: "primary";
    className?: string;
    children?: any;
}

const Button = (props: Props) => {

    return (
        <MaterialButton {...omit(props, ["btnType"])} className={clsx([props.className, { [styles.primary]: !!props.btnType }])}>
            {props.children}
        </MaterialButton>
    );
};

export default Button;