import React from "react";

export default function SVGCloseIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1_568)">
                <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6024 1.66666 10 1.66666C5.39765 1.66666 1.66669 5.39762 1.66669 9.99999C1.66669 14.6024 5.39765 18.3333 10 18.3333Z" stroke="url(#paint0_linear_1_568)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.5 7.5L7.5 12.5" stroke="url(#paint1_linear_1_568)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 7.5L12.5 12.5" stroke="url(#paint2_linear_1_568)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1_568" x1="1.66669" y1="1.66666" x2="18.3334" y2="18.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0033" />
                    <stop offset="1" stopColor="#0F00FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_568" x1="7.5" y1="7.5" x2="12.5" y2="12.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0033" />
                    <stop offset="1" stopColor="#0F00FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_568" x1="7.5" y1="7.5" x2="12.5" y2="12.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0033" />
                    <stop offset="1" stopColor="#0F00FF" />
                </linearGradient>
                <clipPath id="clip0_1_568">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
