import React, { CSSProperties, ReactNode } from "react";
import styles from "./styles/button-gradient.module.scss";

interface Props {
    onClick: () => void;
    children: ReactNode;
    style?: CSSProperties;
}

const ButtonGradient = ({ onClick, style, children }: Props) => {
    return (
        <button className={styles.button} onClick={onClick} style={style}>
            <span className={styles.text}>{children}</span>
        </button>
    );
};

export default ButtonGradient;