import * as React from "react";

function SvgGridMiddle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 66 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#959595"
        d="M12 8h7.737v10.5H12zM20.566 8h7.737v10.5h-7.737zM29.132 8h7.737v10.5h-7.737zM37.697 8h7.737v10.5h-7.737zM46.263 8H54v10.5h-7.737zM12 53.5h7.737V64H12zM20.566 53.5h7.737V64h-7.737zM29.132 53.5h7.737V64h-7.737zM37.697 53.5h7.737V64h-7.737zM46.263 53.5H54V64h-7.737zM12 19.375h7.737v10.5H12zM12 30.75h7.737v10.5H12zM12 42.125h7.737v10.5H12zM20.566 19.375h24.592v33.25H20.566zM46.263 19.375H54v10.5h-7.737zM46.263 30.75H54v10.5h-7.737zM46.263 42.125H54v10.5h-7.737z"
      />
    </svg>
  );
}

export default SvgGridMiddle;