import { Button, Snackbar } from "@material-ui/core";
import { db } from "firebase-app/config";
import { CloseIcon } from "modules/ui";
import React, { useEffect, useState } from "react";
import styles from "./styles/explain-modal.module.scss";

const sessionName = "synk-close-preview";

const ExplainModal = () => {

    const currentState = sessionStorage.getItem(sessionName);

    const [open, setOpen] = useState<boolean>(!currentState);
    const [video, setVideo] = useState<string | undefined>(undefined);

    const getEventDetail = async () => {
        const ref = await db.collection("general").doc("event-detail").get();

        const data = ref.data() as any;

        if (data.controls.miniPlayer) {
            setVideo(data.video.url);
        }
    };

    useEffect(() => {
        getEventDetail();
    }, []);

    const handleClose = () => {
        sessionStorage.setItem(sessionName, "true");
        setOpen(false);
    };

    return (
        <>
            {
                video &&
                <Snackbar
                    className={styles.snackbar}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={open}
                    message={
                        <div>
                            <div className={styles.close}>
                                <Button onClick={handleClose} size="small" style={{ minWidth: "unset" }} aria-label="close">
                                    <CloseIcon />
                                </Button>
                            </div>
                            <video preload="metadata" autoPlay width="100%" loop playsInline>
                                <source src={video + "#t=0.001"} type="video/mp4" />
                            </video>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ExplainModal;