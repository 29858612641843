import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";
import styles from "./styles/camera-perm.module.scss";

interface Props { }

const CameraPerm: React.FC<Props> = () => {
    const [show, setShow] = useState<boolean>(true);

    const handleClick = () => {
        let mediaDevices = navigator.mediaDevices;

        mediaDevices.getUserMedia({ audio: false, video: true }).then(() => {
        }).catch((err) => {
            console.log(err);
        });
        setShow(false);
    };

    return (
        <Dialog open={show} className={styles.content}>
            <DialogContent className={styles.box}>
                <Grid container spacing={2}>
                    <Grid item>
                        <p className={styles.text}>
                            SYNK requires camera access to use the application. Allow access?
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                        >
                            allow access
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            style={{ padding: "1em 0" }}
                            variant="outlined"
                            fullWidth
                            component={Link}
                            to={ROUTES.EVENTS}
                        >
                            Maybe Later
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CameraPerm;