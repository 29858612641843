import React, { CSSProperties } from "react";
import "./styles/horizontal-loader.scss";

interface Props {
    style?: CSSProperties;
}

const HorizontalLoader: React.FC<Props> = (props) => {
    return (
        <div {...props} className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default HorizontalLoader;
