import React, { useState, useEffect } from "react";
import { StateContext } from "context/context";
import { WebcamCapture } from "./components";

import styles from "./styles/photo.module.scss";
import { Header, Loader } from "modules/ui";

export default function Photo() {
    const { state } = StateContext();
    const timer = state.event.cameraTimer;
    const [counter, setCounter] = useState<number>(timer);

    let shutterSound = new Audio("../assets/audio/shutter.mp3");
    let shutter = "../assets/audio/shutter.mp3";
    shutterSound.muted = false;

    const { userNumber } = state;

    useEffect(() => {
        const audio = new Audio("./audio/countdown.mp3");

        // start audio at 5
        if (counter === 5) {
            const playPromise = audio.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => console.log("audio played auto"))
                    .catch(() => console.log("playback prevented"));
            }
        }

        // after countdown has ended play shutter sound
        audio.onended = () => {
            const audio = new Audio("./audio/shutter.mp3");
            const playPromise = audio.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => console.log("audio played auto"))
                    .catch(() => console.log("playback prevented"));
            }
        };
    }, [counter]);

    return (
        <>
            <Header
                background={`${userNumber && counter <= 0 ? "transparent" : "rgba(255,255,255, .6)"}`}
                logoColor={`${userNumber && counter <= 0 ? "white" : "rgba(82, 44, 169, 0.4)"}`}
                showContent={userNumber && counter <= 0 ? false : true}
            />
            <div className={styles.photo}>
                <audio id="audio-element">
                    <source src={shutter}></source>
                </audio>
                {counter >= 0 &&
                    <div className={styles.loader}>
                        <Loader counter={counter} setCounter={setCounter} timer={timer} />
                    </div>
                }
                <WebcamCapture counter={counter} setCounter={setCounter} />
            </div>
        </>
    );
};
