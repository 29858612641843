import React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { HiArrowLeft } from "react-icons/hi";
import { IoMdFlashOff } from "react-icons/io";
import { AiOutlineCamera } from "react-icons/ai";
import { StateContext } from "context/context";
import { isMobile } from "react-device-detect";
import { Button } from "@material-ui/core";
import styles from "./styles/header.module.scss";
import clsx from "clsx";
import { NumberOne, NumberTwo, RetakeText } from "./svg";
import { GoToShare, ShareButton } from "./components";
import { SynkLogo } from "..";
import { AppAction } from "reducers/reducer-action";
import { Menu } from "./menu";

interface Props {
	background: string;
	logoColor: string;
	showContent: boolean;
}

const Header: React.FC<Props> = ({ background, showContent }) => {
	const { state, dispatch } = StateContext();

	const history = useHistory();

	const currentPath = window.location.pathname;
	const { id } = useParams<{ id: string; }>();

	const handleFinish = () => {
		dispatch({
			type: AppAction.SetFinish,
			payload: {
				...state,
			},
		});
		history.push(ROUTES.EVENTS);
		window.location.reload();
	};

	const handleGoBack = (value?: boolean) => {
		if (value) {
			history.push(ROUTES.EVENTS);
		} else {
			history.goBack();
		}
	};

	const header = () => {
		const backArrow = (value?: boolean) => {
			return (
				<div
					onClick={() => handleGoBack(value)}
					className={styles.back}
					style={{ cursor: "pointer" }}
					role="button"
					tabIndex={0}
				>
					<HiArrowLeft />
					{currentPath === "/share" && <span>Edit</span>}
				</div>
			);
		};
		const camera = (
			<div className={styles.camera}>
				<AiOutlineCamera />
				<IoMdFlashOff />
			</div>
		);
		const share = (
			<span className={styles.finish} onClick={handleFinish}>
				Finish
			</span>
		);
		const next = (
			<GoToShare />
		);

		const retake = state.event.photo?.retake && state.retakeCount !== 0 ? (
			<Button
				onClick={() => handleGoBack()}
				className={styles.shareButton}
				aria-label={`Retake Photo. ${state.retakeCount > 0 ? state.retakeCount : 0} attempts remaining.`}
				style={{ minHeight: 36 }}
			>
				<span className={styles.next}>
					{state.retakeCount === 2 && <NumberTwo style={{ transform: "scale(1.2) translateY(-1px)", marginRight: 4 }} />}
					{state.retakeCount === 1 && <NumberOne style={{ transform: "scale(1.2) translateY(-1px)", marginRight: 4 }} />}
					<RetakeText style={{ transform: "scale(1.2)" }} />
				</span>
			</Button>
		) : undefined;

		const shareEvent = <ShareButton className={styles.shareButton} />;

		switch (currentPath) {
			case `${ROUTES.EVENT}/${id}`:
				return { left: backArrow(true), right: shareEvent };
			case ROUTES.SHARE:
				return { left: backArrow(), right: share };
			case ROUTES.PHOTO:
				return { left: backArrow(), right: undefined }; // camera removed for DEMO
			case ROUTES.EDIT_PHOTO:
				return { left: retake, right: next };
			case ROUTES.EVENTS:
				return { left: undefined, right: <Menu /> };
			case ROUTES.FAQ:
				return { left: undefined, right: <Menu /> };
			case ROUTES.TERMS_AND_CONDITIONS:
				return { left: undefined, right: <Menu /> };
			case ROUTES.CONTACT_AND_SUPPORT:
				return { left: undefined, right: <Menu /> };
			default:
				return { left: undefined, right: undefined };
		}
	};

	const { left, right } = header();

	return (
		<>
			<header className={clsx([styles.header, { [styles.mobile]: isMobile }])} style={{ backgroundColor: background }}>
				<div>{showContent && left}</div>
				{currentPath === `${ROUTES.EVENT}/${id}` ? (
					<div className={styles.logo}>
						<SynkLogo style={{ fontSize: "8rem" }} />
					</div>
				) : (
					<button onClick={handleFinish} className={styles.logo}>
						<SynkLogo style={{ fontSize: "8rem" }} />
					</button>
				)}
				<div>{showContent && right}</div>
			</header>
		</>
	);
};

export default Header;

Header.defaultProps = {
	background: "white",
	showContent: true,
};
