import React, { useEffect, useState } from "react";
import { Event, TickerBlob } from "interfaces";
import { AppAction } from "reducers/reducer-action";
import { useHistory } from "react-router-dom";
import { Grid, Snackbar, Typography } from "@material-ui/core";
import { HeaderTag } from "modules/event/components";
import { StateContext } from "context/context";
import { GridType } from "modules/edit-photo/grids/interfaces";
import { BoxShadowWrapper, Button, Counter, RaffleSelection, ScreenTicker, Thermometer } from "modules/ui";
import * as ROUTES from "constants/routes";
import styles from "./styles/event-card.module.scss";
import firebase from "firebase";
import { useGetPaidStatus } from "hooks";

interface Props {
    event: Event;
}

const Card: React.FC<Props> = ({ event }) => {
    const { state, dispatch } = StateContext();
    const [signUps, setSignUps] = useState<number>(0);
    const [showRaffle, setShowRaffle] = useState<boolean>(true);
    const [raffleSuccess, setRaffleSuccess] = useState<boolean>(false);

    const { userHasPaid } = useGetPaidStatus(event);

    const history = useHistory();

    const handleClick = () => {
        dispatch({
            type: AppAction.SetEvent,
            payload: {
                ...state,
                event: event,
                selectedGrid: event?.gridDefault ?? GridType.Portrait
            },
        });

        history.push(`${ROUTES.EVENT}/${event.id}`);
    };

    const handleRaffleClick = () => {
        dispatch({
            type: AppAction.SetEvent,
            payload: {
                ...state,
                event: event,
                selectedGrid: event?.gridDefault ?? GridType.Portrait
            },
        });

        const raffleInput = document.getElementById("raffle-text-field");

        raffleInput?.focus();
    };

    const getTotalSignUps = () => {
        const totalSignUp = firebase.database().ref(`events/${event.id}/summary/numSignUps`).get();

        totalSignUp.then((data) => setSignUps(data.val()));
    };

    useEffect(() => {
        getTotalSignUps();
    }, []);

    const ticker: TickerBlob = {
        tickerArray: event.ticker ?? [],
        display: event.tickerDisplay,
        description: event.tickerDescription,
        buyerDescription: event.tickerBuyerDescription,
        speed: event.tickerSpeed
    };

    const handleFinish = (value: string) => {

        sessionStorage.setItem(`charitySelection-${event.id}`, value);
        setShowRaffle(false);
        setRaffleSuccess(true);

        handleClick();
    };

    const raffleSelectionExist = sessionStorage.getItem(`charitySelection-${event.id}`);

    let total = 0;

    if (event?.total)
        total = (event.total?.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0) / 100);

    return (
        <div
            className={styles.card}
            {...!(event.raffle?.active && !raffleSelectionExist && !userHasPaid) && { onClick: handleClick, style: { cursor: "pointer" } }}
        >
            <div className={styles.title}>
                <Typography variant="h5">{event.title}</Typography>
            </div>
            {(event.banner?.active && event.banner?.text) && <HeaderTag label={event.banner?.text} />}
            <div className={styles.image}>
                <img src={event.bgImage?.url} alt="background" />
            </div>
            <div className={styles.counter}>
                <div style={{ flex: "1 1 auto", minWidth: 1 }}>
                    <Counter
                        startDate={event.startDate}
                        paused={event.datePaused}
                        showDialog={false}
                        label={event.photo?.text !== undefined ? event.photo?.text : ""}
                    />
                </div>
                <div style={{ minWidth: 1, flex: "0 1 auto" }}>
                    {event.raffle?.active && !raffleSelectionExist && !userHasPaid ?
                        <Button
                            onClick={handleRaffleClick}
                            btnType="primary"
                            color="primary"
                            variant="contained"
                            style={{ width: 110, borderRadius: 24 }}
                            className={styles.verticalButton}
                        >
                            Enter Name Below to Join
                        </Button>
                        :
                        <Button
                            onClick={handleClick}
                            btnType="primary"
                            color="primary"
                            variant="contained"
                            className={styles.verticalButton}
                        >
                            Tap to Join
                        </Button>
                    }
                </div>
            </div>
            {event.raffle?.active && (showRaffle && !raffleSelectionExist) && !userHasPaid && <RaffleSelection onFinish={handleFinish} raffle={event.raffle} title={event.title} eventId={event.id} />}

            {event.tickerDisplay && (
                <ScreenTicker isPaidEvent={event.isPaidEvent} ticker={ticker} />
            )}
            {(event.tallies?.total?.active || event.tallies?.participants?.active) &&
                <BoxShadowWrapper>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>Total's So far...</Typography>
                        </Grid>
                        {event.tallies?.participants?.active &&
                            <Grid item>
                                <Thermometer
                                    tally={event.tallies?.participants}
                                    currentNumber={signUps}
                                    tickerLength={event.ticker?.length || 0}
                                    label="Participants"
                                    icon="participants"
                                    positionLeft
                                    color="green"
                                />
                            </Grid>
                        }
                        {event.tallies?.total?.active &&
                            <Grid item>
                                <Thermometer
                                    tally={event.tallies?.total}
                                    currentNumber={total}
                                    label="Donations"
                                    icon="total"
                                    color="yellow"
                                />
                            </Grid>
                        }
                    </Grid>
                </BoxShadowWrapper>
            }
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={!!raffleSuccess}
                autoHideDuration={3000}
                onClose={() => setRaffleSuccess(false)}
                style={{ textAlign: "center" }}
                message={<Typography style={{ textAlign: "center", minWidth: 290 }}>Successfully added {raffleSuccess}.</Typography>}
                key={"verticalHorizontal"}
            />
        </div>
    );
};

export default Card;