import { useRef, useEffect } from 'react';

export const UseClickOutside = (handler: any) => {

  let domNode = useRef<any>();

  useEffect(() => {

    const checkHandler = (event: any) => {
      if (!domNode.current.contains(event.target)) {
        handler(false);
      }
    };
    document.addEventListener('mousedown', checkHandler);

    return () => document.removeEventListener('mousedown', checkHandler);
  }, [handler]);

  return domNode;
};