import React from "react";

export default function SvgThermometerContainer(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="110" height="205" viewBox="0 0 110 205" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_ddii_308_8)">
                <mask id="path-1-inside-1_308_8" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M60 40.5C60 35.8056 56.1945 32 51.5 32C46.8056 32 43 35.8056 43 40.5L43 125.945C36.4891 129.104 32 135.778 32 143.5C32 154.27 40.7304 163 51.5 163C62.2696 163 71 154.27 71 143.5C71 135.778 66.511 129.104 60 125.945L60 40.5Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M60 40.5C60 35.8056 56.1945 32 51.5 32C46.8056 32 43 35.8056 43 40.5L43 125.945C36.4891 129.104 32 135.778 32 143.5C32 154.27 40.7304 163 51.5 163C62.2696 163 71 154.27 71 143.5C71 135.778 66.511 129.104 60 125.945L60 40.5Z" fill="#E1F2FF" />
                <path d="M60 40.5L61.5 40.5V40.5H60ZM43 40.5L44.5 40.5V40.5H43ZM43 125.945L43.6547 127.295L44.5 126.885L44.5 125.945L43 125.945ZM60 125.945L58.5 125.945L58.5 126.885L59.3454 127.295L60 125.945ZM51.5 33.5C55.366 33.5 58.5 36.634 58.5 40.5H61.5C61.5 34.9772 57.0229 30.5 51.5 30.5V33.5ZM44.5 40.5C44.5 36.634 47.634 33.5 51.5 33.5V30.5C45.9772 30.5 41.5 34.9772 41.5 40.5H44.5ZM44.5 125.945L44.5 40.5L41.5 40.5L41.5 125.945L44.5 125.945ZM33.5 143.5C33.5 136.374 37.641 130.212 43.6547 127.295L42.3454 124.596C35.3371 127.995 30.5 135.181 30.5 143.5H33.5ZM51.5 161.5C41.5589 161.5 33.5 153.441 33.5 143.5H30.5C30.5 155.098 39.902 164.5 51.5 164.5V161.5ZM69.5 143.5C69.5 153.441 61.4411 161.5 51.5 161.5V164.5C63.098 164.5 72.5 155.098 72.5 143.5H69.5ZM59.3454 127.295C65.359 130.212 69.5 136.374 69.5 143.5H72.5C72.5 135.181 67.6629 127.995 60.6547 124.596L59.3454 127.295ZM58.5 40.5L58.5 125.945L61.5 125.945L61.5 40.5L58.5 40.5Z" fill="#E0E0E0" mask="url(#path-1-inside-1_308_8)" />
            </g>
            <defs>
                <filter id="filter0_ddii_308_8" x="0" y="0" width="110" height="205" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="12" dy="15" />
                    <feGaussianBlur stdDeviation="13.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_8" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-10" dy="-10" />
                    <feGaussianBlur stdDeviation="11" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_308_8" result="effect2_dropShadow_308_8" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_308_8" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="10" dy="10" />
                    <feGaussianBlur stdDeviation="7" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.152941 0 0 0 0 0.313726 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_308_8" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-10" dy="-10" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_308_8" result="effect4_innerShadow_308_8" />
                </filter>
            </defs>
        </svg>
    );
}