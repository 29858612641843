import { Button } from "modules/ui";
import React from "react";
import { InviteOthersText } from "../svg";

interface Props {
    className: string;
}

const QrCodeModal: React.FC<Props> = ({ className }) => {
    let _navigator: Navigator;

    _navigator = window.navigator;

    const sharedUrl = window.location.href;

    const handleShare = async () => {

        if (_navigator.canShare && _navigator.canShare({ url: sharedUrl })) {
            await _navigator.share({ url: sharedUrl });
        }
    };

    return (
        <Button
            onClick={handleShare}
            aria-label="Share Event"
            className={className}
            style={{ minHeight: 36 }}
        >
            <InviteOthersText style={{ transform: "scale(1.2)", margin: "0px 8px" }} />
        </Button>
    );
};

export default QrCodeModal;