import { Grid, Typography } from "@material-ui/core";
import { Header, Privacy } from "modules/ui";
import React, { useEffect, useState } from "react";
import { StateContext } from "context/context";
import { db } from "firebase-app/config";
import { Navigation } from "../../navigation";
import styles from "./styles/terms-and-conditions.module.scss";

interface Terms {
    id: string;
    description: string;
}

const TermsAndConditions = () => {
    const [terms, setTerms] = useState<string>("");
    const { state } = StateContext();


    const getTerms = async () => {
        const ref = await db.collection("general").doc("terms-and-conditions").get();

        const data = ref.data() as Terms;

        setTerms(data?.description);
    };

    useEffect(() => {
        getTerms();
    }, []);

    return (
        <>
            <Header background="#E1F2FF" logoColor="#522CA9" showContent={true} />
            {!state.privacyPermission && <Privacy />}
            <div className={styles.content}>
                <Navigation />
                <div className={styles.scroll}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: "center" }} variant="h6">Terms And Conditions</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {terms &&
                                <div dangerouslySetInnerHTML={{ __html: terms }} />
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;