import React from "react";

export default function NumberOneSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="5" height="11" viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.84233 0.818182V11H3.30114V2.35938H3.24148L0.805398 3.95028V2.47869L3.34588 0.818182H4.84233Z" fill="url(#paint0_linear_101_41)" />
            <defs>
                <linearGradient id="paint0_linear_101_41" x1="6.09091" y1="16.2078" x2="1.65443" y2="16.0234" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}