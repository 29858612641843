import React, { useEffect, useState } from "react";
import styles from "./styles/thermometer.module.scss";
import clsx from "clsx";
import { ThermometerContainer, ThermometerLiquidYellow, ThermometerLiquidGreen } from "./svgs";
import { Tally } from "interfaces";
import { Typography } from "@material-ui/core";
import moneyHandCoin from "./images/money-hand-coin.png";
import threePersonEmoji from "./images/three-person-emoji.png";
import SlotCounter from "react-slot-counter";

enum IconType {
    Participants = "participants",
    Total = "total",
}

interface Props {
    tally: Tally;
    currentNumber: number;
    label: string;
    icon?: "total" | "participants";
    positionLeft?: boolean;
    color: "yellow" | "green";
    tickerLength?: number;
}

const Thermometer: React.FC<Props> = ({ positionLeft, tally, currentNumber, label, icon, color, tickerLength = 0 }) => {
    const [scale, setScale] = useState<number[]>([]);

    const goal = tally?.goal ?? 0;

    const base = tally?.base ?? 0;

    const loop = () => {

        for (let i = 5; i >= 0; i--) {
            let result = base * Math.round(((goal / 5) * i) / base);
            setScale(prevState => [...prevState, result]);
        }
    };

    useEffect(() => {
        loop();
    }, []);

    const countSwitch = ((tickerLength > currentNumber) && (icon === "participants")) ? tickerLength : currentNumber;

    const currentSpot = Math.ceil(100 - ((countSwitch / goal) * 100));

    const cleanHighLowValue = (value: number) => {

        if (value <= 2)
            return 2;
        else if (value >= 87) {
            return 87;
        }

        return value;
    };

    return (
        <div className={clsx([styles.container, { [styles.left]: positionLeft }])}>
            <div className={styles.svg}>
                <div className={styles.pullMargins}>
                    <ThermometerContainer />
                    <div className={styles.position}>
                        <div className={clsx([styles.scale, { [styles.left]: positionLeft }])}>
                            {scale.map((item, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 &&
                                        <div className={clsx(styles.indicator, styles.short)}>
                                            <span className={styles.tick}></span>
                                            <span className={styles.text}></span>
                                        </div>
                                    }
                                    <div className={styles.indicator}>
                                        <span className={styles.tick}></span>
                                        <span className={styles.text}>{item.toLocaleString()}</span>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className={styles.top}>
                            <div
                                className={styles.flow}
                                style={{ transform: `translateY(${cleanHighLowValue(currentSpot)}px)` }}
                            >
                                {color === "yellow" && <ThermometerLiquidYellow />}
                                {color === "green" && <ThermometerLiquidGreen />}
                            </div>
                        </div>
                        <div className={styles.bottom}>
                            {color === "yellow" && <ThermometerLiquidYellow />}
                            {color === "green" && <ThermometerLiquidGreen />}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Typography variant="body2" style={{ fontWeight: 700, textAlign: "center", marginBottom: 4 }}>{label}</Typography>
                <div className={styles.text}>
                    {icon === "total" && <img style={{ width: 16, height: 16 }} alt="hand with coin on top" src={moneyHandCoin} />}
                    {icon === "participants" && <img style={{ width: 16, height: 16 }} alt="hand with coin on top" src={threePersonEmoji} />}
                    <SlotCounter duration={1} startValueOnce startValue={0} value={`${icon === "total" ? "$" : ""}${countSwitch.toLocaleString()}`} />
                </div>
            </div>
        </div>
    );
};

export default Thermometer;
