import { CSSProperties } from "react";

export const maskStyle = (overlay: string) => {
    return {
        mask: `url(${overlay})`,
        WebkitMask: `url(${overlay})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskPosition: "center",
        WebkitMaskSize: "100% 100%",
        maskSize: "100% 100%",
        maskRepeat: "no-repeat",
        maskPosition: "center",
    } as CSSProperties;
};
