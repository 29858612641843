import { StateContext } from "context/context";
import { db, firebase } from "firebase-app/config";
import { Event, Purchaser } from "interfaces";
import { useState, useEffect } from "react";

const useGetPaidStatus = (event: Event) => {
    const [paidStatusLoaded, setPaidStatusLoaded] = useState<boolean>(false);
    const [userHasPaid, setUserHasPaid] = useState<boolean>(false);

    const [productPrices, setProductsPrices] = useState<any>([]);
    const [paidItems, setPaidItems] = useState<any>([]);
    const [paymentAmount, setPaymentAmount] = useState<number>(0);

    const { state } = StateContext();

    const { userNumber } = state;

    useEffect(() => {

        if (userNumber) {

            // get payments for customer
            const payments = db.collection("customers").doc(state.user?.uid).collection("payments").where("status", "==", "succeeded").get();

            // Get Products for Specific Event
            db.collection("products")
                .where("active", "==", true)
                .where("metadata.eventId", "==", event.id)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (productDoc: any) {
                        const priceSnap = await productDoc.ref.collection("prices").get();

                        await priceSnap.docs.forEach((product: any) => {
                            setProductsPrices((prevState: any) => [...prevState, product.data().product]);
                        });
                    });
                })
                .catch((error) => error.console.log(error));

            payments.then(async (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const payment = doc.data();

                    setPaidItems((prevState: any) => [...prevState, payment.items[0].price]);
                });
            });
        }
    }, []);

    useEffect(() => {

        if (userNumber) {

            const findPaidItem = paidItems.filter((item: any) => {
                return productPrices.some((product: any) => item.product === product);
            });

            const addedToTotal = !event.total?.find(item => item.user === state.user?.uid);

            const doc = db.collection("events").doc(event.id);

            if (addedToTotal && findPaidItem.length > 0) {

                doc.update({
                    total: firebase.firestore.FieldValue.arrayUnion({
                        user: state.user?.uid,
                        price: findPaidItem[0].unit_amount
                    } as Purchaser)
                });
            }

            if (findPaidItem.length > 0) {

                setUserHasPaid(true);
                setPaymentAmount(findPaidItem.unit_amount);

            } else {
                setPaidStatusLoaded(true);
            }

        }
    }, [paidItems, userHasPaid, productPrices, event.id]);

    return { userHasPaid, paidStatusLoaded, paymentAmount };
};

export default useGetPaidStatus;