import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { db, devUrls, storage } from "firebase-app/config";
import NoSleep from "nosleep.js";
import { StateContext } from "../../../../context/context";
import { useHistory, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import * as ROUTES from "../../../../constants/routes";
import { dbLogError } from "../../../../hooks/dbLogError";
import imageCompression from "browser-image-compression";
import dataURLtoFile from "../../../../helpers/dataURLtoFile";
import { isMobile } from "react-device-detect";
import { videoConstraints } from "./data";
import { VerifyUserModal, LoadingModal } from "./modals";
import { AppAction } from "reducers/reducer-action";

type Props = RouteComponentProps<any> & {
    counter: number,
    setCounter: (value: number) => void;
};

const WebcamCapture: React.FC<Props> = ({ counter, setCounter }) => {
    const { state, dispatch } = StateContext();
    const [image, setImage] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const { user, event: { id, title, sendApproveEmail, requiresApproval }, userNumber, picture: { currentImage } } = state;

    const options = {
        maxSizeMB: 5,
        fileType: "image/jpeg"
    };

    const noSleep = new NoSleep();

    const history = useHistory();
    const webcamRef = useRef<Webcam>(null);

    //Captures Images
    const capture = useCallback(
        () => {
            // checks to see if null
            if (webcamRef.current) {
                // maxWidth = 468, maxHeight = 603
                const imageSrc = webcamRef.current.getScreenshot();
                //set a image as string in state
                setImage(imageSrc);

                // // converts to File type
                const toFile = dataURLtoFile(imageSrc, `${title}-${userNumber}`);
                //
                // // compresses image and returns a promise
                const compressedImage = imageCompression(toFile, options);

                // compressed file is converted back into base 64image
                compressedImage.then((content => {
                    const reader = new FileReader();
                    reader.readAsDataURL(content);
                    reader.onloadend = function () {
                        let base64data = reader.result;

                        if (userNumber) {
                            handleSubmit(base64data);

                            if (requiresApproval && (sendApproveEmail !== undefined && sendApproveEmail)) {
                                db.collection("mail").add({
                                    to: devUrls.includes(window.location.hostname) ? ["myronbyrnes@gmail.com"] : ["synkpic@gmail.com"],
                                    message: {
                                        subject: "Image Uploaded For Approval",
                                        text: `Need Approval for ${title}-id: ${id}`
                                    }
                                });
                            }
                        }
                    };
                }));
            }
        },
        [webcamRef]
    );

    useEffect(() => {
        if (counter === 0) {
            dispatch({
                type: AppAction.SetRetakeCount,
                payload: state
            });

            setTimeout(() => {
                capture();
            }, 1000);
        }
    }, [counter]);

    const handleSubmit = async (imageInfo?: any) => {
        setLoading(true);

        const path = `events/${id}/eventImages/${user?.uid}/eventImg`;

        // Create file metadata including the content type
        const metadata = {
            contentType: "image/jpeg",
        };

        await storage.ref(path).putString(imageInfo, "data_url", metadata)
            .then(async (snapshot) => {
                await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    dispatch({
                        type: AppAction.Submit,
                        payload: {
                            ...state,
                            picture: {
                                ...state.picture,
                                currentImage: {
                                    id: user?.uid,
                                    url: imageInfo.toString(),
                                    isApproved: null
                                },
                            },
                            editedPicture: imageInfo.toString()
                        }
                    });
                });
            })
            .catch(async err => {
                await dbLogError(err, "Error 2 handling submit in webcam-capture.tsx", `uid: ${user?.uid}`);
            });
    };

    useEffect(() => {
        if (userNumber && currentImage.url && loading) {
            setTimeout(() => {
                history.push(ROUTES.EDIT_PHOTO);
                getMedia();
            }, 1500);
        }
    }, [userNumber, currentImage.url, loading, history]);

    // when page is loaded adds no sleep function to browser
    useEffect(() => {
        window.addEventListener("load", function enableNoSleep() {
            window.removeEventListener("load", enableNoSleep, false);
            noSleep.enable();
        }, false);
    }, []);

    async function getMedia() {
        let stream = null;
        stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        stream.getTracks().forEach(function (track) {
            track.stop();
        });
    }

    const setHeight = window.screen.availHeight;
    const setWidth = window.screen.availWidth;

    return (
        // styles located globals
        <div className="webcam">
            <div
                className="webcam__grid"
                style={setWidth > setHeight ?
                    {
                        top: "1em",
                        transform: "translate(-50%, 0)",
                        maxHeight: `calc(${setHeight}px - 2em)`
                    } : {}
                }
            ></div>
            <Webcam
                style={{ height: "100vh" }}
                audio={false}
                ref={webcamRef}
                mirrored={true}
                screenshotFormat="image/jpeg"
                videoConstraints={isMobile ? videoConstraints.mobile : videoConstraints.desktop}
            />
            <VerifyUserModal
                title={title}
                onSubmit={handleSubmit}
                error={error}
                open={!userNumber && !!image}
            />
            <LoadingModal loading={loading} error={error} verifiedUser={!!userNumber} />
        </div>
    );
};



export default withRouter(WebcamCapture);
