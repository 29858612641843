import { Button as MaterialButton, Box, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { StateContext } from "context/context";
import { db, devUrls } from "firebase-app/config";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { TickerBlob } from "interfaces";
import styles from "./styles/payment-selection.module.scss";
import { Button, CloseIcon, ScreenTicker } from "modules/ui";

interface Props {
    open: boolean;
    setOpen: (value: boolean) => void;
    eventBackgroundImage?: string;
    eventDescription?: string;
    eventTitle?: string;
    setPaidState?: any;
    ticker: TickerBlob;
}

const PaymentSelection: React.FC<Props> = ({ open, setOpen, eventTitle, eventDescription, eventBackgroundImage, ticker }) => {
    const { state } = StateContext();
    const [value, setValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [products, setProducts] = useState<any>([]);

    useEffect(() => {
        db.collection("products")
            .where("active", "==", true)
            .where("metadata.eventId", "==", state.event.id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (productDoc: any) {

                    let productObject: any = [
                        productDoc.data()
                    ];

                    const priceSnap = await productDoc.ref.collection("prices").get();

                    await priceSnap.docs.forEach((price: any, i: number) => {

                        let pricesObject = {
                            priceId: price.id,
                            data: price.data()
                        };
                        productObject.push(pricesObject);

                        if (i === 0) {
                            setValue(productObject[1].priceId);
                        }
                    });

                    setProducts((prevState: any) => [...prevState, productObject]);
                });
            })
            .catch((error) => error.console.log(error))
            .finally(() => setLoading(false));

    }, [state.event.id]);

    const loadCheckout = async (e: any, priceId: string) => {
        e.preventDefault();

        setLoading(true);

        const docRef = await
            db.collection("customers")
                .doc(state.user?.uid)
                .collection("checkout_sessions")
                .add({
                    mode: "payment",
                    price: priceId,
                    success_url: window.location.href,
                    cancel_url: `${window.location.origin}/events`
                });

        docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data() as any;

            if (error) {
                alert(`An error occured: ${error.message}`);
            }

            if (sessionId) {
                const currentEnvironment = devUrls.includes(window.location.hostname) ?
                    "pk_test_cD4OWMH3AI2yCM5yrcBQB5fV" :
                    "pk_live_51MASFKCYrjUa0McboiMYz6r7Rj10P52fvJLBp2GZ37ljvgx6YA80h9W3Hy3a3tsEbwpzh73oLqlyZrhrWc2QAegA00ujEK9iJ7";

                const stripe = await loadStripe(currentEnvironment);
                stripe?.redirectToCheckout({ sessionId });
            }
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    return (
        <>
            <Dialog className={styles.modal} open={open} onClose={() => setOpen(false)}>
                <DialogContent className={styles.content}>
                    <div>
                        <div className={styles.close}>
                            <MaterialButton size="small" style={{ minWidth: "unset" }} component={Link} to={ROUTES.EVENTS} aria-label="close">
                                <CloseIcon />
                            </MaterialButton>
                        </div>
                        <div className={styles.image}>
                            <img src={eventBackgroundImage} alt={eventTitle} style={{ maxWidth: "100%" }} />
                        </div>
                    </div>
                    <div>
                        <Typography className={styles.title} variant="h6">
                            {eventTitle} is a paid event, please select one of the below payment options.
                        </Typography>
                        <Typography className={styles.description} variant="body1">
                            {eventDescription}
                        </Typography>
                    </div>
                    {state.event.tickerDisplay &&
                        <ScreenTicker isPaidEvent={true} ticker={ticker} />
                    }
                    <div>
                        <Box mt={state.event.ticker?.length !== undefined ? 0 : 2}>
                            {!loading &&
                                <form onSubmit={(e) => loadCheckout(e, value)}>
                                    <FormControl style={{ width: "100%" }}>
                                        <RadioGroup
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            {products.map(([product, price]: any, i: number) => {

                                                return (
                                                    <FormControlLabel
                                                        style={{ width: "100%" }}
                                                        key={i}
                                                        color="primary"
                                                        control={<Radio />}
                                                        className={styles.radio}
                                                        label={
                                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                                <span>
                                                                    {product.description}
                                                                </span>

                                                                <span>
                                                                    <strong>
                                                                        {
                                                                            price.data.unit_amount > 0 ? `$ ${(price.data.unit_amount / 100).toFixed(2)}` : "$ --"
                                                                        }
                                                                    </strong>
                                                                </span>

                                                            </Box>
                                                        }
                                                        value={price.priceId}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <div>
                                        <Button style={{ borderRadius: 90 }} btnType="primary" disabled={!value} type="submit" variant="contained" fullWidth color="primary">Continue to Pay</Button>
                                    </div>
                                </form>}
                            <div className={styles.poweredBy}>
                                Secure Payment Powered by <strong>stripe</strong>
                            </div>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <Dialog open={loading}>
                <DialogContent className={styles.loadContent}>
                    <CircularProgress />
                    <Typography variant="subtitle1">
                        Processing Your Request One Moment Please
                    </Typography>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default PaymentSelection;