import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { StateContext } from "context/context";
import { db } from "firebase-app/config";
import { ImageBase } from "interfaces";
import React, { useEffect, useState } from "react";
import { ButtonGradient } from "../buttons";
import { CustomClose } from "../modals";

const BadgeCollection = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [badges, setBadges] = useState<ImageBase[]>([]);

    const { state } = StateContext();

    const { user } = state;

    const getBadges = () => {
        db.collection("users").doc(user.uid).collection("badges").onSnapshot((result) => {

            const items = result.docs.map(doc => doc.data());

            setBadges(items);
        });
    };

    useEffect(() => {
        if (user)
            getBadges();
    }, []);

    return (
        <>
            {badges.length > 0 && <ButtonGradient onClick={() => setOpen(true)}>Badge Collection</ButtonGradient>}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle style={{ textAlign: "center" }}>Badges</DialogTitle>
                <CustomClose onClose={() => setOpen(false)} />
                <DialogContent>
                    {badges.map((badge, index) => <img key={index} src={badge.url} alt="badge" style={{ maxWidth: 200 }} />)}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BadgeCollection;