import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes'

interface Props {
  user: number | string;
  path: string;
  image: string;
}

export const ProtectedRoute: React.FC<Props> = ({ user, path, image, children, ...restProps }) => (
  <Route
    {...restProps}
    path={path}
    render={({ location }) => {
      if (user && image) return children
      if (!user || !image) return (<Redirect to={{
        pathname: ROUTES.EVENTS,
        state: { from: location }
      }} />)
      if (user) return (
        <Redirect to={{
          pathname: ROUTES.EVENTS,
          state: { from: location }
        }} />
      )
      return null;
    }}
  >
  </Route>
)


export const ProtectedRouteHome: React.FC<Props> = ({ user, path, image, children, ...restProps }) => (
  <Route
    {...restProps}
    path={path}
    render={({ location }) => {
      if (!user || !image) return (<Redirect to={{
        pathname: ROUTES.HOME,
        state: { from: location }
      }} />)
      if (user) return (
        <Redirect to={{
          pathname: ROUTES.EVENTS,
          state: { from: location }
        }} />
      )
      return null;
    }}
  >
  </Route>
)
