import React, { CSSProperties, ReactElement } from "react";
import styles from "./styles/box-shadow-wrapper.module.scss";

interface Props {
    children: ReactElement | ReactElement[];
    style?: CSSProperties;
}

const BoxShadowWrapper: React.FC<Props> = ({ children, style }) => (
    <div className={styles.container} style={style}>
        {children}
    </div>
);

export default BoxShadowWrapper;