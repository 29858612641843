import ImageBase from "./image-base";

enum BadgeKey {
    Background = "background",
    TopLeft = "topLeft",
    TopRight = "topRight",
    BottomRight = "bottomRight",
    BottomLeft = "bottomLeft",
    BottomMiddle = "bottomMiddle",
    Portrait = "portrait",
}

interface BadgeImage extends ImageBase {
    active?: boolean;
    xAxis?: number;
    yAxis?: number;
}

interface Badge {
    [BadgeKey.Background]?: BadgeImage;
    [BadgeKey.BottomMiddle]?: BadgeImage;
    [BadgeKey.TopLeft]?: BadgeImage;
    [BadgeKey.TopRight]?: BadgeImage;
    [BadgeKey.BottomLeft]?: BadgeImage;
    [BadgeKey.BottomRight]?: BadgeImage;
    [BadgeKey.Portrait]?: BadgeImage;
}

export default Badge;
export { BadgeKey };
