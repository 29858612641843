import React from "react";
import { StateContext } from "context/context";
import { maskStyle } from "./data";
import styles from "./styles/grid-portrait.module.scss";

const GridPortrait = () => {
    const { state } = StateContext();

    const userImage = state.picture.currentImage;

    return (
        <div
            className={styles.portrait}
            style={{
                overflow: "hidden",
                ...(state.event.outlineImage?.url && state.event.useOutline) &&
                maskStyle(state.event.outlineImage.url)
            }}
        >
            <img src={userImage.url.toString()} alt="" className={styles.image} />
        </div>
    );
};

export default GridPortrait;