import { Header, Privacy } from "modules/ui";
import { StateContext } from "context/context";
import { Navigation } from "../../navigation";
import { FaqContent } from "./components";
import styles from "./styles/faq.module.scss";

const FaqPage = () => {
    const { state } = StateContext();

    return (
        <>
            <Header background="#E1F2FF" logoColor="#522CA9" showContent={true} />
            {!state.privacyPermission && <Privacy />}
            <div className={styles.content}>
                <Navigation />
                <div className={styles.scroll}>
                    <FaqContent />
                </div>
            </div>
        </>
    );
};
export default FaqPage;