import * as React from "react";

function SvgGridFeatureHundred(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="12" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2339" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4673" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7012" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2336" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4675" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7012" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="24.9019" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="24.9019" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2339" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2534" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4673" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4871" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7012" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7207" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="16.2336" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="20.4675" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="24.7012" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="12" y="53.1655" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="28.9348" y="53.1655" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4749" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7087" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9424" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="24.9194" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="8" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4749" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7087" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9424" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="30.5361" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="24.9019" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="13.6338" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="19.2681" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="24.9019" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4749" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4946" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7087" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7283" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9424" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9619" y="53.1831" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="36.2637" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="37.4749" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="41.7087" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="45.9424" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="58.7998" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="33.2412" y="53.1655" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="41.8975" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="47.5317" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="50.176" y="53.1655" width="3.82398" height="5.2006" fill="#959595" />
            <rect x="18" y="16" width="14" height="19" fill="#4600E0" />
            <rect x="35" y="38" width="14" height="19" fill="#4600E0" />
        </svg>
    );
}

export default SvgGridFeatureHundred;