import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { ButtonGradient, CustomClose } from "modules/ui";
import { db } from "firebase-app/config";

const EventDetailModal = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [video, setVideo] = useState<string | undefined>(undefined);

	const getEventDetail = async () => {
		const ref = await db.collection("general").doc("event-detail").get();

		const data = ref.data() as any;

		if (data.controls.video) {
			setVideo(data.video.url);
		}

		if (data.controls.description) {
			setDescription(data.description);
		}
	};

	useEffect(() => {
		getEventDetail();
	}, []);

	return (
		<>
			{(description || video) && <ButtonGradient onClick={() => setOpen(!open)}>What is SYNK?</ButtonGradient>}
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle style={{ textAlign: "center" }}>
					What is Synk?
				</DialogTitle>
				<DialogContent>
					<CustomClose onClose={() => setOpen(false)} />
					<Grid spacing={2} container>
						{video &&
							<Grid xs={12} item>
								<div style={{ margin: "auto", width: "fit-content", maxHeight: 400, height: "100%", maxWidth: "100%" }}>
									<video height="100%" preload="metadata" width="100%" controls playsInline>
										<source src={video + "#t=0.001"} type="video/mp4" />
									</video>
								</div>
							</Grid>
						}
						{description &&
							<Grid xs={12} item>
								<div style={{ maxHeight: 300, overflow: "hidden auto" }}>
									<div dangerouslySetInnerHTML={{ __html: description }} />
								</div>
							</Grid>
						}
					</Grid>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</>
	);
};

export default EventDetailModal;