import React, { useState } from "react";
import { StateContext } from "context/context";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { AppAction } from "reducers/reducer-action";
import styles from "./styles/privacy.module.scss";
import { Typography, Grid, Button } from "@mui/material";

export default function Privacy() {
	const [show, setShow] = useState<boolean>(true);
	const { state, dispatch } = StateContext();
	const [error, setError] = useState<string>("");
	const [tab, setTab] = useState<boolean>(true);

	const pathName = window.location.pathname;

	const handleClick = (accept: boolean) => {
		dispatch({
			type: AppAction.SetPermission,
			payload: {
				...state,
				privacyPermission: accept,
			},
		});
		if (pathName === "/events") {
			setShow(false);
			setError("");
		} else {
			setError("Privacy Policy must be agreed upon in order to join events.");
		}
	};

	return (
		<div className={styles.privacy} style={{ display: show ? "" : "none" }}>
			<div className={styles.container}>
				<div className={styles.close}>
					<Button component={Link} to={ROUTES.EVENTS} aria-label="close">
						<IoMdClose aria-hidden />
					</Button>
				</div>
				<h3 className={styles.title}>
					{tab ? "We care about your privacy" : "Terms and Conditions"}
					<button onClick={() => setTab(!tab)} className={styles.tab}>{tab ? "View Terms" : "View Privacy"}</button>
				</h3>
				{error && <h4 className={styles.error}>{error}</h4>}

				{tab ? (
					<div className={styles.content}>
						<Grid spacing={2} container>
							<Grid xs={12} item>
								Protecting your private information is our priority. This Statement of Privacy applies to SYNK
								or any web page that SYNK.com forwards to, including https://synk-edae9.web.app, and Level-
								Up Life, Inc and governs data collection and usage. For the purposes of this Privacy Policy,
								unless otherwise noted, all references to Level-Up Life, Inc include SYNK.com or any
								forwarded web address from SYNK.com and LUL. The LUL application is a Virtual event. One
								category of event types is designed to help non-profit organizations raise money and allows users
								to donate and take a selfie to create a mosaic of all the participants. The results and pictures are
								shared to all participants. Another event type uses SYNK functionality to market company
								products and brands. application. By using the LUL application, you consent to the data practices
								described in this statement.
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Collection of your Personal Information
								</Typography>
								<Typography variant="body2">
									In order to better provide you with products and services offered, LUL may collect personally
									identifiable information, such as your:
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography ml={4} variant="body2">
									- Phone Number
								</Typography>
								<Typography ml={4} variant="body2">
									- Pictures
								</Typography>
							</Grid>
							<Grid xs={12} item>
								We do not collect any personal information about you unless you voluntarily provide it to us.
								However, you may be required to provide certain personal information to us when you elect to
								use certain products or services. These may include: (a) registering for an account; (b) entering a
								sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers
								from selected third parties; (d) sending us an email message; (e) submitting your credit card or
								other payment information when ordering and purchasing products and services. To wit, we will
								use your information for, but not limited to, communicating with you in relation to services
								and/or products you have requested from us. We also may gather additional personal or non-
								personal information in the future.
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Use of your Personal Information
								</Typography>
								<Typography variant="body1">
									LUL collects and uses your personal information to operate and deliver the services you have
									requested.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									LUL may also use your personally identifiable information to inform you of other products or
									services available from LUL and its affiliates.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Photo Release
								</Typography>
								<Typography variant="body1">
									For good and valuable consideration, the receipt of which is hereby acknowledged, I, SYNK
									user, hereby grant LUL permission to use my likeness in a photograph in any and all of its
									publications, including but not limited to all of LUL&#39;s printed and digital publications. I
									understand and agree that any photograph using my likeness will become property of LUL and
									will not be returned or relinquished.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I acknowledge that since my participation with LUL is voluntary, I will receive no financial
									compensation.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I hereby irrevocably authorize LUL to edit, alter, copy, exhibit, publish or distribute this photo
									for purposes of publicizing LUL&#39;s programs or for any other related, lawful purpose. In addition,
									I waive the right to inspect or approve the finished product, including written or electronic copy,
									wherein my likeness appears. Additionally, I waive any right to royalties or any other
									compensation arising out of, or related to, the use of the photograph.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I hereby irrevocably authorize LUL to edit, alter, copy, exhibit, publish or distribute this photo
									for purposes of publicizing LUL&#39;s programs or for any other related, lawful purpose. In addition,
									I waive the right to inspect or approve the finished product, including written or electronic copy,
									wherein my likeness appears. Additionally, I waive any right to royalties or any other
									compensation arising out of, or related to, the use of the photograph.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I hereby hold harmless and release and forever discharge LUL from all claims, demands, and
									causes of action which I, my heirs, representatives, executors, administrators, or any other
									persons acting on my behalf or on behalf of my estate have or may have by reason of this
									authorization.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I am at least 18 years of age and am competent to contract in my own name. I have read this
									release before signing below and I fully understand the contents, meaning, and impact of this
									release.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Sharing Information with Third Parties
								</Typography>
								<Typography variant="body1">
									LUL does not sell, rent or lease its customer lists to third parties.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									LUL may share data with trusted partners to help perform statistical analysis, send you email or
									postal mail, provide customer support, or arrange for deliveries. All such third parties are
									prohibited from using your personal information except to provide these services to LUL, and
									they are required to maintain the confidentiality of your information.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									LUL may disclose your personal information, without notice, if required to do so by law or in the
									good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
									with legal process served on LUL or the site; (b) protect and defend the rights or property of
									LUL; and/or (c) act under exigent circumstances to protect the personal safety of users of LUL,
									or the public.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Right to Deletion
								</Typography>
								<Typography variant="body1">
									Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
								</Typography>
								<ul>
									<li>
										<Typography ml={1} variant="body1">
											Delete your personal information from our records; and
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Direct any service providers to delete your personal information from their records.
										</Typography>
									</li>
								</ul>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Please note that we may not be able to comply with requests to delete your personal information
									if it is necessary to:
								</Typography>
								<ul>
									<li>
										<Typography ml={1} variant="body1">
											Complete the transaction for which the personal information was collected, fulfill the
											terms of a written warranty or product recall conducted in accordance with federal law,
											provide a good or service requested by you, or reasonably anticipated within the context
											of our ongoing business relationship with you, or otherwise perform a contract between
											you and us;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
											activity; or prosecute those responsible for that activity;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Debug to identify and repair errors that impair existing intended functionality;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Exercise free speech, ensure the right of another consumer to exercise his or her right of
											free speech, or exercise another right provided for by law;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Comply with the California Electronic Communications Privacy Act;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Engage in public or peer-reviewed scientific, historical, or statistical research in the
											public interest that adheres to all other applicable ethics and privacy laws, when our
											deletion of the information is likely to render impossible or seriously impair the
											achievement of such research, provided we have obtained your informed consent;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Enable solely internal uses that are reasonably aligned with your expectations based on
											your relationship with us;
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Comply with an existing legal obligation; or
										</Typography>
									</li>
									<li>
										<Typography ml={1} variant="body1">
											Otherwise use your personal information, internally, in a lawful manner that is
											compatible with the context in which you provided the information.
										</Typography>
									</li>
								</ul>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Children Under Thirteen
								</Typography>
								<Typography variant="body1">
									LUL does not knowingly collect personally identifiable information from children under the age
									of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
									permission to use this application.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									External Data Storage Sites
								</Typography>
								<Typography variant="body1">
									We may store your data on servers provided by third party hosting vendors with whom we have
									contracted.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Changes to this Statement
								</Typography>
								<Typography variant="body1">
									LUL reserves the right to change this Privacy Policy from time to time. We will notify you about
									significant changes in the way we treat personal information by sending a notice to the primary
									email address specified in your account, by placing a prominent notice on our application, and/or
									by updating any privacy information. Your continued use of the application and/or Services
									available after such modifications will constitute your: (a) acknowledgment of the modified
									Privacy Policy; and (b) agreement to abide and be bound by that Policy.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Contact Information
								</Typography>
								<Typography variant="body1">
									LUL welcomes your questions or comments regarding this Statement of Privacy. If you believe
									that LUL has not adhered to this Statement, please contact LUL at:
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Level-Up Life, Inc <br />
									668 N Coast Hwy, STE 205<br />
									Laguna Beach, California 92651
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Email Address: <br />
									cs@synk.com
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Telephone number: <br />
									3107338288
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Effective as of January 01, 2024
								</Typography>
							</Grid>
						</Grid>
					</div>
				) : (
					<div className={styles.content}>
						<Grid spacing={2} container>
							<Grid xs={12} item>
								<Typography variant="h6">
									Agreement between User and SYNK.com or any web page that SYNK.com forwards to,
									including but not limited to https://synk-edae9.web.app
								</Typography>
								<Typography variant="body1">
									Welcome to SYNK.com or any web page that SYNK.com forwards to, including https://synk-
									edae9.web.app. The SYNK.com or any web page that SYNK.com forwards to, including
									https://synk-edae9.web.app website (the &quot;Site&quot;) is comprised of various web pages operated by
									Level-Up Life, Inc (&quot;LUL&quot;). SYNK.com or any web page that SYNK.com forwards to,
									including https://synk-edae9.web.app is offered to you conditioned on your acceptance without
									modification of the terms, conditions, and notices contained herein (the &quot;Terms&quot;). Your use of
									SYNK.com or any web page that SYNK.com forwards to, including https://synk-edae9.web.app
									constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy
									of them for your reference.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									SYNK.com or any web page that SYNK.com forwards to, including https://synk-edae9.web.app
									is an E-Commerce Site.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									SYNK.com is a for profit organization that produces virtual events. One category of event types
									is designed to help non-profit organizations raise money and allows users to donate and take a
									selfie to create a mosaic of all the participants. The results and pictures are shared to all
									participants. Another event type uses SYNK functionality to market company products and
									brands.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Privacy
								</Typography>
								<Typography variant="body1">
									Your use of SYNK.com or any web page that SYNK.com forwards to, including https://synk-
									edae9.web.app is subject to LUL&#39;s Privacy Policy. Please review our Privacy Policy, which also
									governs the Site and informs users of our data collection practices.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Electronic Communications
								</Typography>
								<Typography variant="body1">
									Visiting SYNK.com or any web page that SYNK.com forwards to, including https://synk-
									edae9.web.app or sending emails or texts to LUL constitutes electronic communications. You
									consent to receive electronic communications and you agree that all agreements, notices,
									disclosures and other communications that we provide to you electronically, via email, text and
									on the Site, satisfy any legal requirement that such communications be in writing.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Photo Release
								</Typography>
								<Typography variant="body1">
									For good and valuable consideration, the receipt of which is hereby acknowledged, I, SYNK
									user, hereby grant LUL permission to use my likeness in a photograph in any and all of its
									publications, including but not limited to all of LUL&#39;s printed and digital publications. I
									understand and agree that any photograph using my likeness will become property of LUL and
									will not be returned.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I acknowledge that since my participation with LUL is voluntary, I will receive no financial
									compensation.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I hereby irrevocably authorize LUL to edit, alter, copy, exhibit, publish or distribute this photo
									for purposes of publicizing LUL&#39;s programs or for any other related, lawful purpose. In addition,
									I waive the right to inspect or approve the finished product, including written or electronic copy,
									wherein my likeness appears. Additionally, I waive any right to royalties or any other
									compensation arising out of, or related to, the use of the photograph.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I hereby hold harmless and release and forever discharge LUL from all claims, demands, and
									causes of action which I, my heirs, representatives, executors, administrators, or any other
									persons acting on my behalf or on behalf of my estate have or may have by reason of this
									authorization.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									I am at least 18 years of age and am competent to contract in my own name. I have read this
									release before signing below and I fully understand the contents, meaning, and impact of this
									release.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Children Under Thirteen
								</Typography>
								<Typography variant="body1">
									LUL does not knowingly collect, either online or offline, personal information from persons
									under the age of thirteen. If you are under 18, you may use SYNK.com or any web page that
									SYNK.com forwards to, including https://synk-edae9.web.app only with permission of a parent
									or guardian.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Cancellation/Refund Policy
								</Typography>
								<Typography variant="body1">
									Once a user donates to any virtual event, that donation is NOT refundable for any reason.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Links to Third Party Sites/Third Party Services
								</Typography>
								<Typography variant="body1">
									SYNK.com or any web page that SYNK.com forwards to, including https://synk-edae9.web.app
									may contain links to other websites that LUL has no control management involvement
									whatsoever (&quot;Linked Sites&quot;). The Linked Sites are not under the control of LUL and LUL is not
									responsible for the contents of any Linked Site, including without limitation any link contained
									in a Linked Site, or any changes or updates to a Linked Site. LUL is providing these links to you
									only as a convenience, and the inclusion of any link does not imply endorsement by LUL of the
									site or any association with its operators.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Certain services made available via SYNK.com or any web page that SYNK.com forwards to,
									including https://synk-edae9.web.app are delivered by third party sites and organizations. By
									using any product, service or functionality originating from the SYNK.com or any web page that
									SYNK.com forwards to, including https://synk-edae9.web.app domain, you hereby acknowledge
									and consent that LUL may share such information and data with any third party with whom LUL
									has a contractual relationship to provide the requested product, service or functionality on behalf
									of SYNK.com or any web page that SYNK.com forwards to, including https://synk-
									edae9.web.app users and customers.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									No Unlawful or Prohibited Use/Intellectual Property
								</Typography>
								<Typography variant="body1">
									You are granted a non-exclusive, non-transferable, revocable license to access and use
									SYNK.com or any web page that SYNK.com forwards to, including https://synk-edae9.web.app
									strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant

									to LUL that you will not use the Site for any purpose that is unlawful or prohibited by these
									Terms. You may not use the Site in any manner which could damage, disable, overburden, or
									impair the Site or interfere with any other party&#39;s use and enjoyment of the Site. You may not
									obtain or attempt to obtain any materials or information through any means not intentionally
									made available or provided for through the Site. Any content displayed or produced on the site is
									at the sole discretion of LUL. LUL will solely decide what to display, share, produce or
									otherwise use and is under no obligation to any user with regard to these decisions.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									All content included as part of the Service, such as text, graphics, logos, images, as well as the
									compilation thereof, and any software used on the Site, is the property of LUL or its suppliers
									and protected by copyright and other laws that protect intellectual property and proprietary
									rights. You agree to observe and abide by all copyright and other proprietary notices, legends or
									other restrictions contained in any such content and will not make any changes thereto.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
									derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
									LUL content is not for resale. Your use of the Site does not entitle you to make any unauthorized
									use of any protected content, and in particular you will not delete or alter any proprietary rights
									or attribution notices in any content. You will use protected content solely for your personal use,
									and will make no other use of the content without the express written permission of LUL and the
									copyright owner. You agree that you do not acquire any ownership rights in any protected
									content. We do not grant you any licenses, express or implied, to the intellectual property of
									LUL or our licensors except as expressly authorized by these Terms.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									International Users
								</Typography>
								<Typography variant="body1">
									The Service is controlled, operated and administered by LUL from our offices within the USA. If
									you access the Service from a location outside the USA, you are responsible for compliance with
									all local laws. You agree that you will not use the LUL Content accessed through SYNK.com or
									any web page that SYNK.com forwards to, including https://synk-edae9.web.app in any country
									or in any manner prohibited by any applicable laws, restrictions or regulations.
								</Typography>

							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Indemnification
								</Typography>
								<Typography variant="body1">
									You agree to indemnify, defend and hold harmless LUL, its officers, directors, employees,
									agents and third parties, for any losses, costs, liabilities and expenses (including reasonable
									attorney&#39;s fees) relating to or arising out of your use of or inability to use the Site or services, any
									user postings made by you, your violation of any terms of this Agreement or your violation of
									any rights of a third party, or your violation of any applicable laws, rules or regulations. LUL
									reserves the right, at its own cost, to assume the exclusive defense and control of any matter
									otherwise subject to indemnification by you, in which event you will fully cooperate with LUL
									in asserting any available defenses.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Mediation
								</Typography>
								<Typography variant="body1">
									The parties agree that any dispute or controversy, arising out of or in connection with these
									Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or
									in equity for damages or any other relief, or any alleged breach thereof, shall, prior to arbitration,
									be subject to Mediation thereto. Such Mediation shall be administrated by National Arbitration
									and Mediation (“NAM”) and governed by their Comprehensive Dispute Resolution Rules and
									Procedures and the Fee Schedule in effect at the time such claim is filed with NAM.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Arbitration
								</Typography>
								<Typography variant="body1">
									In the event the parties are not able to resolve, via Mediation, any dispute between them arising
									out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract,
									tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be
									resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted
									by a single neutral arbitrator and administered by the American Arbitration Association, or a
									similar arbitration service selected by the parties, in a location in California to be mutually
									agreed upon by the parties. The arbitrator&#39;s award shall be final, and judgment may be entered
									upon it in any court having jurisdiction. In the event that any legal or equitable action,
									proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing
									party shall be entitled to recover its costs and reasonable attorney&#39;s fees. The parties agree to
									arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising
									as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims
									that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act
									governs the interpretation and enforcement of this provision. The entire dispute, including the
									scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This
									arbitration provision shall survive the termination of these Terms and Conditions.
								</Typography>

							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Class Action Waiver
								</Typography>
								<Typography variant="body1">
									Any arbitration under these Terms and Conditions will take place on an individual basis; class
									arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE
									THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH&#39;S
									INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
									PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH
									AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE
									OTHER. Further, unless both you and LUL agree otherwise, the arbitrator may not consolidate
									more than one person&#39;s claims, and may not otherwise preside over any form of a representative
									or class proceeding.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Liability Disclaimer
								</Typography>
								<Typography variant="body1">
									THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
									AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
									TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
									INFORMATION HEREIN. LEVEL-UP LIFE, INC AND/OR ITS SUPPLIERS MAY MAKE
									IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									LEVEL-UP LIFE, INC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
									THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
									OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
									GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
									EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
									PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT
									WARRANTY OR CONDITION OF ANY KIND. LEVEL-UP LIFE, INC AND/OR ITS
									SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
									REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
									RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
									MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-
									INFRINGEMENT.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
									SHALL LEVEL-UP LIFE, INC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
									INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
									DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
									LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
									WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
									TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
									PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
									SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
									OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
									CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
									LEVEL-UP LIFE, INC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
									POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
									ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
									INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
									YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
									THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
									USING THE SITE.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Termination/Access Restriction
								</Typography>
								<Typography variant="body1">
									LUL reserves the right, in its sole discretion, to terminate your access to the Site and the related
									services or any portion thereof at any time, without notice. To the maximum extent permitted by
									law, this agreement is governed by the laws of the State of California and you hereby consent to
									the exclusive jurisdiction and venue of courts in California in all disputes arising out of or
									relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not
									give effect to all provisions of these Terms, including, without limitation, this section.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									You agree that no joint venture, partnership, employment, or agency relationship exists between
									you and LUL as a result of this agreement or use of the Site. LUL&#39;s performance of this
									agreement is subject to existing laws and legal process, and nothing contained in this agreement
									is in derogation of LUL&#39;s right to comply with governmental, court and law enforcement requests
									or requirements relating to your use of the Site or information provided to or gathered by LUL
									with respect to such use. If any part of this agreement is determined to be invalid or
									unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers
									and liability limitations set forth above, then the invalid or unenforceable provision will be
									deemed superseded by a valid, enforceable provision that most closely matches the intent of the
									original provision and the remainder of the agreement shall continue in effect.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Unless otherwise specified herein, this agreement constitutes the entire agreement between the
									user and LUL with respect to the Site and it supersedes all prior or contemporaneous
									communications and proposals, whether electronic, oral or written, between the user and LUL
									with respect to the Site. A printed version of this agreement and of any notice given in electronic
									form shall be admissible in judicial or administrative proceedings based upon or relating to this
									agreement to the same extent and subject to the same conditions as other business documents and
									records originally generated and maintained in printed form. It is the express wish to the parties
									that this agreement and all related documents be written in English.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Changes to Terms
								</Typography>
								<Typography variant="body1">
									LUL reserves the right, in its sole discretion, to change the Terms under which SYNK.com or
									any web page that SYNK.com forwards to, including https://synk-edae9.web.app is offered. The
									most current version of the Terms will supersede all previous versions. LUL encourages you to
									periodically review the Terms to stay informed of our updates.
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="h6">
									Contact Us
								</Typography>
								<Typography variant="body1">
									LUL welcomes your questions or comments regarding the Terms:
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Level-Up Life, Inc
									668 N. Coast Hwy, Ste 205
									Laguna Beach, California 92651
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Email Address:
									cs@synk.com
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="body1">
									Effective as of January 01, 2024
								</Typography>
							</Grid>
						</Grid>
					</div>
				)}

				<div className={styles.options}>
					<button
						onClick={() => handleClick(false)}
						className={styles.reject}
					>
						reject all
					</button>
					<button onClick={() => handleClick(true)} className={styles.accept}>
						accept all
					</button>
				</div>
			</div>
		</div>
	);
}
