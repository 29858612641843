import React from "react";

export default function SvgThermometerLiquid(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="37" height="130" viewBox="0 0 37 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_308_13)">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5 3C16.0147 3 14 5.01472 14 7.5V96.7118C8.1948 98.6052 4 104.063 4 110.5C4 118.508 10.4919 125 18.5 125C26.5081 125 33 118.508 33 110.5C33 104.063 28.8052 98.6052 23 96.7118V7.5C23 5.01472 20.9853 3 18.5 3Z" fill="url(#paint0_linear_308_13)" />
            </g>
            <ellipse cx="13.5" cy="107" rx="6.5" ry="6" fill="url(#paint1_radial_308_13)" />
            <ellipse cx="27" cy="113" rx="5" ry="4" fill="url(#paint2_radial_308_13)" fillOpacity="0.55" />
            <rect x="16" y="8" width="1" height="84" rx="0.5" fill="url(#paint3_linear_308_13)" fillOpacity="0.7" />
            <defs>
                <filter id="filter0_d_308_13" x="0" y="0" width="37" height="130" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_13" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_13" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_308_13" x1="48.3702" y1="126.771" x2="19.0988" y2="126.186" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor="#156D05" />
                    <stop offset="1" stopColor="#84E700" />
                </linearGradient>
                <radialGradient id="paint1_radial_308_13" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.5 107) rotate(90) scale(6 6.5)">
                    <stop stopColor="white" stopOpacity="0.7" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint2_radial_308_13" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27 113) rotate(90) scale(4 5)">
                    <stop stopColor="#EFEFEF" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint3_linear_308_13" x1="15.5" y1="51.9385" x2="18" y2="51.9385" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}