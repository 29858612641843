import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Question } from "interfaces";
import styles from "./styles/faq-item.module.scss";

interface Props {
    item: Question;
}

const FaqItem: React.FC<Props> = ({ item }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            className={styles.accordion}
        >
            <AccordionSummary
                expandIcon={expanded ? <FaMinus style={{ fontSize: "12px" }} /> : <FaPlus style={{ fontSize: "12px" }} />}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Typography style={{ fontWeight: 500 }}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{item.description}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default FaqItem;