import { Button } from "@material-ui/core";
import { CloseIcon } from "../icons";
import styles from "./styles/custom-close.module.scss";

interface Props {
    onClose: () => void;
}

const CustomClose = ({ onClose }: Props) => (
    <div className={styles.close}>
        <Button onClick={onClose} size="small" style={{ minWidth: "unset", transform: "scale(1.5)" }} aria-label="close">
            <CloseIcon />
        </Button>
    </div>
);

export default CustomClose;