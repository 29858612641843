import * as React from "react";

function SvgGridFeatureTwentyFive(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="12" y="8" width="7.73684" height="10.5" fill="#959595" />
            <rect x="20.5657" y="8" width="7.73685" height="10.5" fill="#959595" />
            <rect x="20.6055" y="19.375" width="7.73685" height="10.5" fill="#959595" />
            <rect x="20.6055" y="30.75" width="7.73685" height="10.5" fill="#959595" />
            <rect x="20.6055" y="42.1602" width="7.73685" height="10.5" fill="#959595" />
            <rect x="29.1316" y="8" width="7.73683" height="10.5" fill="#959595" />
            <rect x="29.1714" y="19.375" width="7.73685" height="10.5" fill="#959595" />
            <rect x="29.1714" y="30.75" width="7.73685" height="10.5" fill="#959595" />
            <rect x="29.1714" y="42.1602" width="7.73685" height="10.5" fill="#959595" />
            <rect x="37.6973" y="8" width="7.73684" height="10.5" fill="#959595" />
            <rect x="37.7371" y="19.375" width="7.73684" height="10.5" fill="#959595" />
            <rect x="37.7371" y="30.75" width="7.73684" height="10.5" fill="#959595" />
            <rect x="37.7371" y="42.1602" width="7.73684" height="10.5" fill="#959595" />
            <rect x="46.2632" y="8" width="7.73682" height="10.5" fill="#959595" />
            <rect x="12" y="53.5" width="7.73684" height="10.5" fill="#959595" />
            <rect x="20.5659" y="53.5" width="7.73685" height="10.5" fill="#959595" />
            <rect x="29.1316" y="53.5" width="7.73683" height="10.5" fill="#959595" />
            <rect x="37.6975" y="53.5" width="7.73686" height="10.5" fill="#959595" />
            <rect x="46.2632" y="53.5" width="7.73682" height="10.5" fill="#959595" />
            <rect x="12" y="19.375" width="7.73684" height="10.5" fill="#959595" />
            <rect x="12" y="30.75" width="7.73684" height="10.5" fill="#959595" />
            <rect x="12" y="42.125" width="7.73684" height="10.5" fill="#959595" />
            <rect x="46.2632" y="19.375" width="7.73682" height="10.5" fill="#959595" />
            <rect x="46.2632" y="30.75" width="7.73682" height="10.5" fill="#959595" />
            <rect x="46.2632" y="42.125" width="7.73682" height="10.5" fill="#959595" />
            <rect x="17" y="14" width="14" height="19" fill="#4600E0" />
            <rect x="34" y="36" width="14" height="19" fill="#4600E0" />
        </svg>
    );
}

export default SvgGridFeatureTwentyFive;