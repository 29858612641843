import React, { CSSProperties } from "react";
import clsx from "clsx";
import styles from "./styles/grid-large.module.scss";
import { maskStyle } from "./data";
import { StateContext } from "context/context";
import { GridType } from "./interfaces";


interface Props {
    gridSize: number;
    selectedGrid: string;
}

const GridLarge: React.FC<Props> = ({ selectedGrid, gridSize }) => {
    const { state: { event, gridImages } } = StateContext();

    const grid = gridImages.filter(x => x.type !== "feature").slice(0, gridSize);

    return (
        <div
            className={styles.grid}
            style={{
                overflow: "hidden",
                ...(event.outlineImage?.url && event.useOutline) &&
                maskStyle(event.outlineImage.url)
            }}
        >
            {grid.map((image, i: number) => (
                <div
                    key={i}
                    style={{
                        gridArea: `${i === 0 && selectedGrid === GridType.Middle ? "m" : { i }}`,
                        // "--value-x": `${Math.floor(Math.random() * 2001) - 1000}px`,
                        // "--value-y": `${Math.floor(Math.random() * 2001) - 1000}px`,
                        // "--value-r": `${Math.floor(Math.random() * 501) - 250}deg`,
                        // "--value-d": `${(Math.random() * 5 + 2).toFixed(1)}s`,
                        // "--value-b": `${(Math.random() * 10).toFixed(1)}px`,
                    } as CSSProperties}
                    className={clsx([styles["image-container"],
                        // { [styles.bounce]: animate }
                    ])}>
                    <div className={styles.whiteBorder}>
                        <img src={image.image} alt="" className={styles.image} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default GridLarge;
