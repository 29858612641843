import React, { useState, useEffect } from "react";
import { StateContext } from "context/context";
import styles from "./styles/edit-photo.module.scss";
import clsx from "clsx";
import { BadgeCollection, ButtonGradient, FilterGrid, FilterOverlay, Header, RotationRight } from "modules/ui";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Overlay } from "interfaces";
import { AppAction } from "reducers/reducer-action";
import { GridFeatureHundred, GridFeatureHundredCenter, GridFeatureLarge, GridFeatureLargeCenter, GridHundred, GridLarge, GridPortrait } from "./grids";
import { FeatureLayer, GeneralLayer } from "./layers";
import { GridType } from "./grids/interfaces";
import { gridData } from "./grids/data/grid-data";
import { DonationModal, InviteOthersModal } from "./modals";
import { ApprovalBar } from "./components";

export default function EditPhoto() {
    const { state, dispatch } = StateContext();
    const [selected, setSelected] = useState<any>(0);
    const [layer, setLayer] = useState<number>(state.event.overlayDefault || 0);
    const [error, setError] = useState<string>("");

    const handleMosaic = (option: GridType) => {
        dispatch({
            type: AppAction.SetSelectedGrid,
            payload: {
                ...state,
                selectedGrid: option
            }
        });
    };

    useEffect(() => {
        dispatch({
            type: AppAction.GetGridImages,
            payload: state
        });

    }, []);

    const noOverlay: Overlay = {
        name: "none",
        url: "none",
        path: "none"
    };

    const overlaysList: Overlay[] = [noOverlay, ...state.event.overlays];

    const { mosaic, featurePersonNumberOne, featureUserImageDisplay, featurePersonImage } = state.event;

    const loaded = state.gridImages.length <= 101;

    return (
        <>
            <Header background="#e1f2ff" logoColor="white" showContent={true} />
            <div style={{ height: 72, background: "#e1f2ff" }}></div>
            <div className={styles.editPhoto}>
                <div className={styles.dimensions}>
                    <div className={clsx(styles.loader, { [styles.active]: loaded, [styles.shareLoaded]: state.loading })}>
                        <div>
                            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <p className={styles.errorImage}>{error ? error : state.loading ? "Generating Image..." : "Populating Grid..."}</p>
                        </div>
                    </div>
                    <div className={styles.imageContainer} id="grid-creation">
                        <div className={styles.gridWrapper}>
                            {state.selectedGrid === GridType.Portrait &&
                                <GridPortrait />
                            }

                            {(state.selectedGrid === GridType.Middle && state.picture.currentImage.url) &&
                                <GridLarge selectedGrid={GridType.Middle} gridSize={17} />
                            }

                            {(state.selectedGrid === GridType.TwentyFive && state.picture.currentImage.url) &&
                                <GridLarge selectedGrid={GridType.TwentyFive} gridSize={25} />
                            }

                            {(state.selectedGrid === GridType.Hundred && state.picture.currentImage.url) &&
                                <GridHundred gridSize={100} />
                            }

                            {(state.selectedGrid === GridType.FeatureTwentyFive && state.picture.currentImage.url) &&
                                <GridFeatureLarge gridSize={30} />
                            }

                            {(state.selectedGrid === GridType.FeatureTwentyFiveCenter && state.picture.currentImage.url) &&
                                <GridFeatureLargeCenter gridSize={17} />
                            }

                            {(state.selectedGrid === GridType.FeatureHundred && state.picture.currentImage.url) &&
                                <GridFeatureHundred gridSize={84} />
                            }

                            {(state.selectedGrid === GridType.FeatureHundredCenter && state.picture.currentImage.url) &&
                                <GridFeatureHundredCenter gridSize={65} />
                            }
                        </div>
                        <div className={styles.gridWrapper}>
                            {state.event.featuredImageItem?.path && (
                                state.selectedGrid === GridType.FeatureTwentyFive ||
                                state.selectedGrid === GridType.FeatureHundred ||
                                state.selectedGrid === GridType.FeatureTwentyFiveCenter ||
                                state.selectedGrid === GridType.FeatureHundredCenter
                            ) && <FeatureLayer />
                            }
                            {(layer !== 0 && overlaysList[layer]?.url) && <GeneralLayer layer={layer} />}
                        </div>
                    </div>
                </div>

                <div className={styles.optionsContainer}>
                    {state.event.donation &&
                        <div className={styles.modalOptions}>
                            <ButtonGradient onClick={() => window.open(state.event.donateUrl)}>
                                {state.event.donateText ? state.event.donateText : "Donate"}
                            </ButtonGradient>
                            <InviteOthersModal />
                            <BadgeCollection />
                        </div>
                    }
                    <div className={styles.photoOptions}>
                        <div className={styles.overflow}>
                            {selected === 0 &&
                                gridData.map((grid, i) => (
                                    <span key={i}>
                                        <button
                                            disabled={loaded}
                                            className={clsx([styles.option, { [styles.active]: grid.name === state.selectedGrid }])}
                                            style={(
                                                (
                                                    grid.name !== GridType.FeatureTwentyFiveCenter &&
                                                    grid.name !== GridType.FeatureHundredCenter &&
                                                    grid.name !== GridType.FeatureTwentyFive &&
                                                    grid.name !== GridType.FeatureHundred &&
                                                    mosaic[grid.name] && mosaic[grid.name]) ||
                                                (!!featurePersonNumberOne && featureUserImageDisplay && mosaic[grid.name]) ||
                                                (!!featurePersonImage?.url && featureUserImageDisplay && mosaic[grid.name]) ||
                                                (
                                                    ((!!featurePersonNumberOne || !!featurePersonImage?.url) && grid.name === GridType.FeatureHundredCenter && mosaic[grid.name]) ||
                                                    ((!!featurePersonNumberOne || !!featurePersonImage?.url) && grid.name === GridType.FeatureTwentyFiveCenter && mosaic[grid.name])) ||
                                                (featureUserImageDisplay && grid.name === GridType.FeatureHundredCenter && mosaic[grid.name]) ||
                                                (featureUserImageDisplay && grid.name === GridType.FeatureTwentyFiveCenter && mosaic[grid.name])


                                            ) ? {} : { display: "none" }}
                                            onClick={() => handleMosaic(grid.name)}
                                        >
                                            <div className={styles.filterBackground}>
                                                <div></div>
                                            </div>
                                            {grid.img}
                                            <div className={styles.activeIcon}>
                                                <RotationRight />
                                            </div>
                                        </button>
                                    </span>
                                ))}

                            {selected === 1 &&
                                overlaysList.map((overlay: any, i: number) => (
                                    <span key={i}>
                                        <button
                                            className={clsx([styles.option, { [styles.active]: i === layer }])}
                                            onClick={() => setLayer(i)}
                                        >
                                            <div className={styles.filterBackground}>
                                                <div></div>
                                            </div>
                                            {i === 0 ?
                                                <div style={{ height: 70, width: 70 }}>
                                                    <div style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "80%",
                                                        width: "80%",
                                                        fontWeight: 600,
                                                        background: "rgba(0,0,0, .4)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        transform: "translate(-50%, -50%)",
                                                        borderRadius: 8
                                                    }}
                                                    ><AiOutlineEyeInvisible /></div>
                                                </div>
                                                :
                                                <img className={styles.optionOverlay} src={overlay.url} alt={overlay.name} />
                                            }
                                        </button>
                                    </span>
                                ))}
                        </div>
                    </div>
                    <div className={styles.filterOptions} style={{ borderRadius: 80 }}>
                        <button
                            disabled={loaded}
                            onClick={() => setSelected(0)}
                            className={clsx([styles.option, { [styles.notActive]: !(selected === 0) }])}
                        >
                            <div className={styles.filterBackground}>
                                <div></div>
                            </div>
                            <FilterGrid />
                        </button>
                        <button
                            disabled={loaded}
                            onClick={() => setSelected(1)}
                            className={clsx([styles.option, { [styles.notActive]: !(selected === 1) }])}
                        >
                            <div className={styles.filterBackground}>
                                <div></div>
                            </div>
                            <FilterOverlay />
                        </button>
                    </div>
                </div>
            </div>
            <DonationModal />
            <ApprovalBar />
        </>
    );

};