import React, { useEffect } from "react";
import { Dialog, DialogContent, Typography, Button as MaterialButton } from "@material-ui/core";
import { StateContext } from "context/context";
import styles from "./styles/share-modal.module.scss";
import PotGoldDonation from "./images/pot-gold-donation.png";
import Glitter from "./images/glitter.png";
import { CongratulationsSvg } from "./svgs";
import { clsx } from "clsx";
import { CloseIcon } from "modules/ui";
import { AppAction } from "reducers/reducer-action";

const DonationModal = () => {
    const { state, dispatch } = StateContext();
    const { event, donationModal } = state;

    const sum = event.ticker?.reduce((sum, current) => sum + current.price, 0);

    const handleClose = () => {
        dispatch({
            type: AppAction.SetDonationModal,
            payload: { ...state, donationModal: false }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch({
                type: AppAction.SetDonationModal,
                payload: { ...state, donationModal: true }
            });
        }, 24000);
    }, []);

    return (
        <Dialog className={styles.container} open={event.donationModal && donationModal && !!event.isPaidEvent && sum !== undefined} onClose={handleClose}>
            <DialogContent className={styles.content}>
                <div className={styles.close}>
                    <MaterialButton onClick={handleClose} size="small" style={{ minWidth: "unset" }} aria-label="close">
                        <CloseIcon />
                    </MaterialButton>
                </div>
                <div className={styles.imageContainer}>
                    <img className={clsx([styles.image, styles.position])} src={Glitter} alt="glitter" />
                    <img className={styles.image} src={PotGoldDonation} alt="pot of gold with a rainbow and sun" />
                </div>
                <div>
                    <CongratulationsSvg style={{ width: "100%" }} aria-label="Congratulations" />
                </div>
                <Typography className={styles.title} variant="h5">YOU helped {event.title} raise</Typography>

                <div className={styles.total}>
                    ${(sum! / 100).toLocaleString(undefined)}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DonationModal;