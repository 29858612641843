import { db } from "firebase-app/config";

/**
 * Function that will log all of our client errors to a Firestore collection for debugging purposes
 * @param e
 * @param location
 * @param userData
 */
export const dbLogError = async (e: Error, location: string, userData?: string) => {
    await db.collection('errors').doc()
        .set({
            date: Date.now(),
            location: location,
            isAdminPanel: false,
            userData: userData || 'no data',
            error: e.message || 'No error message to display',
        });
};
