import { Button, Snackbar } from "@material-ui/core";
import { StateContext } from "context/context";
import { db } from "firebase-app/config";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "constants/routes";

const ApprovalBar = () => {
    const [approved, setApproved] = useState(true);
    
    const { state } = StateContext();
    const history = useHistory();

    // grabs image from Events to see if image is approved or not
    const doc = db.collection("events").doc(state.event.id).collection("eventImages").doc(state.picture.currentImage.id);

    // listens for approval of picture from admin
    doc.onSnapshot(docSnapshot => {
        setApproved(docSnapshot.data()?.isApproved);
    });

    const handleRetake = () => {
        history.push(ROUTES.PHOTO);
    }

    return (
        <Snackbar open={(approved !== null && !approved)} autoHideDuration={6000}>
            <div style={{
                background: "lightGrey",
                padding: 16,
                borderRadius: 16,
                fontWeight: "bold",
                textAlign: "center"
            }}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                    SORRY, but your picture has been denied
                    <Button onClick={handleRetake}>Retake</Button>
                </div>
            </div>
        </Snackbar>
    );
};

export default ApprovalBar;