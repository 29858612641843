import React, { useEffect } from "react";
import { StateContext } from "context/context";
import { storage } from "firebase-app/config";
import { dbLogError } from "hooks/dbLogError";
import styles from "./styles/feature-layer.module.scss";
import { AppAction } from "reducers/reducer-action";
import { getDownloadImage } from "modules/ui";

const FeatureLayer = () => {
    const { state, dispatch } = StateContext();

    const getFeatureImage = async () => {
        if (state.event.featuredImageItem?.path) {

            const url = await storage.ref(state.event.featuredImageItem?.path).getDownloadURL();

            getDownloadImage(url).then(response => {

                const base64 = response as string;

                dispatch({
                    type: AppAction.SetFeatureLayer,
                    payload: {
                        ...state,
                        featureLayer: base64
                    }
                });
            }).catch(async (error) => {
                console.error("oops, something went wrong ", error);
                await dbLogError(error, "error setting Signature Image signature-image.tsx");
            });
        }
    };

    useEffect(() => {
        if (state.featureLayer === undefined) getFeatureImage();
    }, []);

    return (
        <div className={styles.layer}>
            <div className={styles.background}>
                {state.featureLayer &&
                    <img src={state.featureLayer} alt="add on"></img>
                }
            </div>
        </div>);
};

export default FeatureLayer;