import React, { CSSProperties } from "react";
import clsx from "clsx";
import styles from "./styles/grid-feature-hundred.module.scss";
import { GridImage } from "./interfaces";
import { StateContext } from "context/context";
import { maskStyle } from "./data";

interface Props {
    gridSize: number;
}

const GridFeatureLarge: React.FC<Props> = ({ gridSize }) => {
    const { state: { event, gridImages } } = StateContext();

    const grid = gridImages.slice(0, gridSize);

    return (
        <div
            className={styles.grid}
            style={{
                overflow: "hidden",
                ...(event.outlineImage?.url && event.useOutline) &&
                maskStyle(event.outlineImage.url)
            }}
        >
            {grid.map((image: GridImage, i: number) => (
                <div
                    key={i}
                    style={{
                        gridArea: `${image.type === "original" ? "m" : image.type === "feature" ? "f" : { i }}`,
                        // "--value-x": `${Math.floor(Math.random() * 2001) - 1000}px`,
                        // "--value-y": `${Math.floor(Math.random() * 2001) - 1000}px`,
                        // "--value-r": `${Math.floor(Math.random() * 501) - 250}deg`,
                        // "--value-d": `${(Math.random() * 5 + 2).toFixed(1)}s`,
                        // "--value-b": `${(Math.random() * 10).toFixed(1)}px`,
                    } as CSSProperties}
                    className={clsx([styles["image-container"],
                    {
                        // [styles.bounce]: animate, 
                        [styles.bordered]: image.type === "original" || image.type === "feature"
                    }])}>
                    {
                        image.type === "original" || image.type === "feature" ?
                            <div className={styles.extraBorder}>
                                <img src={image.image} alt="" className={styles.image} />
                            </div> :
                            <div className={styles.whiteBorder}>
                                <img src={image.image} alt="" className={styles.image} />
                            </div>
                    }
                </div>
            ))}
        </div>
    );
};

export default GridFeatureLarge;
