import React, { useState, useEffect } from "react";
import { db } from "firebase-app/config";
import { dbLogError } from "hooks/dbLogError";
import { StateContext } from "context/context";
import styles from "./styles/events.module.scss";
import { Navigation } from "./navigation";
import { Event } from "interfaces";
import { EventCard } from "./event";
import { Header, LoadIcon, Privacy } from "modules/ui";
import { MiniPlayerModal } from "./components";

const Events = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { state } = StateContext();

    function getEvents() {
        const query = db
            .collection("events")
            .where("startDate", ">=", new Date());

        query.orderBy("startDate").onSnapshot(
            (querySnapshot) => {
                setEvents(
                    querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data() as any,
                    }))
                );
                setLoading(false);
            },
            (err) => {
                console.error(err);
                dbLogError(err, "Loading events in events.tsx");
            }
        );
    }

    useEffect(() => {
        setLoading(true);
        getEvents();
    }, []);

    const { privacyPermission } = state;

    return (
        <>
            <Header background="#E1F2FF" logoColor="#522CA9" showContent={true} />
            {!privacyPermission && <Privacy />}
            {loading ?
                <LoadIcon centered /> :
                <div className={styles.events}>
                    <Navigation />
                    <div className={styles.scroll}>
                        {events.map(event => <EventCard key={event.id} event={event} />)}
                    </div>
                </div>
            }
            {privacyPermission && <MiniPlayerModal />}
        </>
    );
};

export default Events;