import React, { useEffect, useState } from "react";
import { StateContext } from "context/context";
import { Overlay } from "interfaces";
import { storage } from "firebase-app/config";
import { dbLogError } from "hooks/dbLogError";
import { getDownloadImage } from "modules/ui";

interface Props {
    layer: number;
}

const noOverlay: Overlay = {
    name: "none",
    url: "none",
    path: "none"
};

const GeneralLayer: React.FC<Props> = ({ layer }) => {
    const [image, setImage] = useState("");
    const { state } = StateContext();

    const overlaysList: Overlay[] = [noOverlay, ...state.event.overlays];

    useEffect(() => {
        const getLayer = async () => {
            if (state.event.featuredImageItem?.path) {

                const url = await storage.ref(overlaysList[layer].path).getDownloadURL();

                getDownloadImage(url).then(response => {

                    const url = response as string;

                    setImage(url);
                }).then(() => {

                }).catch(async (error) => {
                    console.error('oops, something went wrong ', error);
                    await dbLogError(error, 'error setting Signature Image signature-image.tsx');
                });
            }
        };

        getLayer();
    }, []);

    return (
        <img className="edit-photo__layer-image" src={image} alt="Layer creation" />
    );
};

export default GeneralLayer;