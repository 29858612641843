import { Grid, GridType } from "../interfaces";
import {
    IconGridHundred,
    IconGridMiddle,
    IconGridTwentyFive,
    IconGridBasic,
    IconGridFeatureHundred,
    IconGridFeatureTwentyFive,
    IconGridFeatureTwentyFiveCenter,
    IconGridFeatureHundredCenter,
} from "../icons";

export const gridData: Grid[] = [
    {
        id: 6,
        img: <IconGridBasic style={{ fontSize: "4.8rem" }} />,
        name: GridType.Portrait,
        iNum: 100,
    },
    {
        id: 1,
        img: <IconGridMiddle style={{ fontSize: "4.8rem" }} />,
        name: GridType.Middle,
        iNum: 17,
    },
    {
        id: 2,
        img: <IconGridTwentyFive style={{ fontSize: "4.8rem" }} />,
        name: GridType.TwentyFive,
        iNum: 25,
    },
    {
        id: 3,
        img: <IconGridHundred style={{ fontSize: "4.8rem" }} />,
        name: GridType.Hundred,
        iNum: 100,
    },
    {
        id: 4,
        img: <IconGridFeatureTwentyFive style={{ fontSize: "4.8rem" }} />,
        name: GridType.FeatureTwentyFive,
        iNum: 25,
    },
    {
        id: 5,
        img: <IconGridFeatureHundred style={{ fontSize: "4.8rem" }} />,
        name: GridType.FeatureHundred,
        iNum: 100,
    },
    {
        id: 7,
        img: <IconGridFeatureTwentyFiveCenter style={{ fontSize: "4.8rem" }} />,
        name: GridType.FeatureTwentyFiveCenter,
        iNum: 17,
    },
    {
        id: 8,
        img: <IconGridFeatureHundredCenter style={{ fontSize: "4.8rem" }} />,
        name: GridType.FeatureHundredCenter,
        iNum: 88,
    },
];
