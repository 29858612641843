import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { StateContext } from "context/context";
import { BadgeContent, ButtonGradient, CustomClose } from "modules/ui";
import { useState } from "react";
import { AppAction } from "reducers/reducer-action";
import styles from "./styles/invite-others.module.scss";

const InviteOthersModal = () => {
    const { state, dispatch } = StateContext();
    const [preview, setPreview] = useState<string | undefined>(undefined);

    const { event, invitationModal } = state;

    let _navigator: Navigator = window.navigator;

    const sharedUrl = state.event.qrCode;

    const handleShare = async () => {

        let toBlob;
        let file;

        if (preview) {
            toBlob = await (await fetch(preview)).blob();
            file = new File([toBlob], "Badge.png", { type: toBlob.type });
        }

        if (_navigator.canShare && _navigator.canShare({ url: sharedUrl, files: (file && event.useBadge) ? [file] : [] })) {
            await _navigator.share({
                url: sharedUrl,
                files: (file && event.useBadge) ? [file] : []
            });
        }

        setTimeout(() => {
            handleClose();
        }, 1000);
    };

    const handleClose = () => {
        dispatch({
            type: AppAction.SetInvitationModal,
            payload: { ...state, invitationModal: false }
        });
    };

    const handleOpen = () => {
        dispatch({
            type: AppAction.SetInvitationModal,
            payload: { ...state, invitationModal: true }

        });
    };

    return (
        <>
            <ButtonGradient onClick={handleOpen}>Badge</ButtonGradient>
            <Dialog className={styles.modal} open={invitationModal} onClose={handleClose}>
                <DialogTitle style={{ textAlign: "center", paddingBottom: 0, paddingRight: 64 }}>{state.event?.inviteModalText}</DialogTitle>
                <DialogContent style={{ paddingBottom: 32 }}>
                    <CustomClose onClose={handleClose} />
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} style={{ minHeight: 100 }}>
                            {(event?.inviteImageModal?.url && event.useImageModal) &&
                                <div style={{ display: "grid", placeContent: "center" }}>
                                    <img style={{ maxWidth: 400 }} src={state.event.inviteImageModal?.url} alt="invite invitation" />
                                </div>
                            }
                            {event.badge && event?.useBadge && <BadgeContent badge={event.badge} preview={preview} setPreview={setPreview} />}
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGradient style={{ width: "100%" }} onClick={handleShare}>Share and Invite</ButtonGradient>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default InviteOthersModal;