import { StateContext } from "context/context";
import { dbLogError } from "hooks/dbLogError";
import html2canvas from "html2canvas-objectfit-fix";
import { ShareIcon } from "modules/ui/icons";
import { useHistory } from "react-router-dom";
import { AppAction } from "reducers/reducer-action";
import { ShareText } from "../svg";
import styles from "./styles/go-to-share.module.scss";
import * as ROUTES from "constants/routes";
import clsx from "clsx";
import { Button } from "@material-ui/core";

/**
 * Creates Image for share screen
 */
const GoToShare = () => {
    const { state, dispatch } = StateContext();
    const nodeTwo: HTMLElement | null = document.getElementById("grid-creation");

    const history = useHistory();

    const createImage = async () => {

        if (!nodeTwo) {
            // setError("Please select another grid and try again");
            console.error("Grid creation error \"Edit-Photo.tsx\" CreateImage();");
            return;
        }

        // important for grid placement when placed in canvas
        window.scrollTo(0, 0);
        try {
            await html2canvas(nodeTwo)?.then(function (canvas) {

                const img = canvas.toDataURL("image/png", { allowTaint: true, useCORS: true, scale: 5 });

                return new Promise((resolve) => {
                    let newCanvas = document.createElement("canvas");

                    let ctx = newCanvas.getContext("2d");

                    let image = new Image();

                    image.src = img;
                    image.onload = () => {

                        let width = image.naturalWidth;
                        let height = image.naturalHeight;

                        newCanvas.width = width;
                        newCanvas.height = height;

                        if (state.event.outlineImage?.url !== undefined && state.event.useOutline) {
                            let layerImage = new Image();

                            layerImage.src = state.event.outlineImage?.url;
                            layerImage.crossOrigin = "anonymous";

                            layerImage.onload = () => {

                                ctx?.drawImage(layerImage, 0, 0, width, height);

                                ctx!.globalCompositeOperation = "source-in";

                                ctx!!.imageSmoothingEnabled = false;
                                ctx?.drawImage(image, 0, 0, width, height);

                                const clipImage = newCanvas.toDataURL();

                                resolve(clipImage);

                                dispatch({
                                    type: AppAction.SetMosaic,
                                    payload: {
                                        ...state,
                                        picture: {
                                            ...state.picture,
                                            currentMosaic: clipImage
                                        },
                                        editedPicture: clipImage,
                                    }
                                });
                            };
                        }

                        if (!state.event.useOutline || state.event.outlineImage?.url === undefined) {

                            ctx!!.imageSmoothingEnabled = false;
                            ctx?.drawImage(image, 0, 0, width, height);

                            const clipImage = newCanvas.toDataURL();

                            resolve(clipImage);

                            dispatch({
                                type: AppAction.SetMosaic,
                                payload: {
                                    ...state,
                                    picture: {
                                        ...state.picture,
                                        currentMosaic: clipImage
                                    },
                                    editedPicture: clipImage,
                                }
                            });
                        }
                    };
                });
            });

        } catch (err: any) {
            console.error(err);
            await dbLogError(err, "error getting random images via createImage.tsx");
        }
    };

    const handleClick = () => {
        dispatch({
            type: AppAction.SetLoading,
            payload: {
                ...state,
                loading: true
            }
        });

        createImage().then(() => {
            dispatch({
                type: AppAction.SetLoading,
                payload: { ...state, loading: false }
            });

            history.push(ROUTES.SHARE);
        });
    };

    return (
        <Button
            aria-label="share"
            className={clsx([styles.shareButton, { [styles.loading]: state.loading }])}
            disabled={state.loading || state.gridImages.length <= 101}
            onClick={handleClick}
        >
            <span className={styles.next}><ShareIcon aria-hidden /><ShareText style={{ transform: "scale(1.2)" }} /></span>
        </Button>
    );
};

export default GoToShare;