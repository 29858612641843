import React, { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { PhoneInput } from "./components";
import { StateContext } from "context/context";
import { auth, db, firebase } from "firebase-app/config";
import * as ROUTES from "constants/routes";
import { Box, TextField, Typography } from "@material-ui/core";
import styles from "./styles/phone-login.module.scss";
import { AppAction } from "reducers/reducer-action";
import { Button } from "modules/ui";

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult;
    }
}

interface Props {
    home?: boolean;
    event?: boolean;
    webcamCapture?: boolean;
    onWebcamSubmit?: () => void;
}

const PhoneLogin: React.FC<Props> = ({ home, webcamCapture, onWebcamSubmit }) => {
    const { state, dispatch } = StateContext();
    const [number, setNumber] = useState<string>("");
    const [OTP, setOTP] = useState<string>("");
    const [active, setActive] = useState<boolean>(false);

    const history = useHistory();

    auth.useDeviceLanguage();

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
            "size": "invisible",
            "callback": (response: any) => {
            }
        });
    };

    const requestOTP = (e: any) => {
        e.preventDefault();

        if (isValidPhoneNumber(number || "")) {
            generateRecaptcha();

            let appVerifier = window.recaptchaVerifier;

            auth.signInWithPhoneNumber(number, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).                   
                    window.confirmationResult = confirmationResult;

                }).catch((error) => { });
        }
    };

    const verifyOTP = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        let value = e.target.value;

        setOTP(value);

        if (value.length === 6) {
            let confirmationResult = window.confirmationResult;

            confirmationResult.confirm(value).then((result) => {
                // User signed in successfully.
                const user = result.user;
                
                if (user) db.collection("users").doc(user.uid).set({});

                dispatch({
                    type: AppAction.SetPhone,
                    payload: {
                        ...state,
                        userNumber: number,
                        user: user,
                    }
                });

                if (home) {
                    history.push(ROUTES.EVENTS);
                }

                if (webcamCapture && onWebcamSubmit && !state.event.photo.retake) {
                    onWebcamSubmit();
                }

            }).catch((error) => {

                // User couldn't sign in (bad verification code?)
                // ...
            });
        }
    };

    return (
        <>
            <form onSubmit={requestOTP} action="" className={styles.content}>
                <PhoneInput number={number} setNumber={setNumber} />
                <Button
                    fullWidth
                    type="submit"
                    style={{ opacity: (isValidPhoneNumber(number ?? "") && !active) ? "1" : ".5", borderRadius: 90 }}
                    disabled={!isValidPhoneNumber(number ?? "")}
                    variant="contained"
                    color="primary"
                    btnType="primary"
                    onClick={() => setActive(true)}
                >
                    {active ? "Enter Code Below" : "Verify Phone Number (Required)"}
                </Button>
                <Box>
                    <TextField
                        disabled={!active}
                        className={styles.input}
                        autoComplete="one-time-code"
                        fullWidth
                        onChange={verifyOTP}
                        variant="outlined"
                        placeholder="Please Enter"
                        type="number"
                        value={OTP}
                        size={home ? "medium" : "small"}
                        InputProps={{ inputProps: { min: 0, max: 6 } }}
                    />
                </Box>
                <div>
                    <Typography variant="body1">Please Enter the One time pin sent to your phone</Typography>
                </div>
                <div style={{ position: "absolute", zIndex: -1 }} id="recaptcha-container"></div>
            </form>
            {home && <span className="home__divider">or</span>}
        </>
    );
};

export default PhoneLogin;