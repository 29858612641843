import * as React from "react";

function DonationTag(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="140"
            height="30"
            viewBox="0 0 140 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_i_1_147)">
                <path d="M2.48008 9.04806C-1.44345 6.2035 0.568721 0 5.41491 0H134.585C139.431 0 141.443 6.2035 137.52 9.04805L134.894 10.9519C132.14 12.9483 132.14 17.0517 134.894 19.0481L137.52 20.9519C141.443 23.7965 139.431 30 134.585 30H5.41492C0.568726 30 -1.44346 23.7965 2.48007 20.9519L5.10613 19.0481C7.85968 17.0517 7.85968 12.9483 5.10613 10.9519L2.48008 9.04806Z" fill="#5003FB" />
            </g>
            <defs>
                <filter id="filter0_i_1_147" x="0.405273" y="0" width="139.189" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="9" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.085677 0 0 0 0 0 0 0 0 0 0.291667 0 0 0 0.8 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_147" />
                </filter>
            </defs>
        </svg>
    );
}

export default DonationTag;