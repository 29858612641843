import React, { CSSProperties } from "react";
import { maskStyle } from "./data";
import styles from "./styles/grid-hundred.module.scss";
import clsx from "clsx";
import { StateContext } from "context/context";

interface Props {
    gridSize: number;
}

const GridHundred: React.FC<Props> = ({ gridSize }) => {
    const { state: { event, gridImages } } = StateContext();

    const grid = gridImages.filter(i => i.type !== "feature").slice(0, gridSize);

    return (
        <div id="grid-creation" className={styles["grid-hundred"]}
            style={{
                overflow: "hidden",
                ...(event.outlineImage?.url && event.useOutline) &&
                maskStyle(event.outlineImage.url)
            }}
        >
            {grid.map((image, i: number) => (
                <div key={i}
                    style={{
                        // "--value-x": `${(Math.floor(Math.random() * 2001) - 1000)}px`,
                        // "--value-y": `${(Math.floor(Math.random() * 2001) - 1000)}px`,
                        // "--value-r": `${(Math.floor(Math.random() * 501) - 250)}deg`,
                        // "--value-d": `${((Math.random() * 5) + 2).toFixed(1)}s`,
                        // "--value-b": `${(Math.random() * 10).toFixed(1)}px`
                    } as CSSProperties}
                    className={clsx([styles["image-container"],
                        // { [styles.bounce]: animate }
                    ])}
                >
                    <div className={styles.whiteBorder}>
                        <img src={image.image} alt="" className={styles.image} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default GridHundred;