import { omit } from "lodash";
import React, { CSSProperties } from "react";
import "./styles/load-icon.scss";

interface Props {
  style?: CSSProperties;
  className?: string;
  centered?: boolean;
}

const LoadIcon: React.FC<Props> = (props) => {
  return (
    <div  {...props.centered && { style: { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" } }}>
      <div {...omit(props, ["centered"])} >
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadIcon;