import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import styles from "./styles/counter.module.scss";

interface CountDown {
    months: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

interface Props {
    startDate: any;
    setDisplay?: (value: boolean) => void;
    paused?: boolean;
    onEvent?: boolean;
    showDialog?: boolean;
    setShowDialog?: (value: boolean) => void;
    label: string;
}

const defaults = {
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
} as CountDown;

const Counter: React.FC<Props> = ({ startDate, setDisplay, paused = false, showDialog = true, setShowDialog, label }) => {
    const [countDown, setCountDown] = useState<CountDown>(defaults);
    const [open, setOpen] = useState<boolean>(false);

    const { days, hours, minutes, seconds } = countDown;

    // calculates the time remaining before an event
    useEffect(() => {
        // if timer is necessary to display instead
        if (!paused) {
            const counter = setInterval(() => {
                const getTimeUntilEvent = (time: typeof startDate) => {
                    const today = new Date();
                    const timeDiff = time.toMillis() - today.getTime();
                    const tempTime = moment.duration(timeDiff);
                    setCountDown({ months: tempTime.months(), days: tempTime.days(), hours: tempTime.hours(), minutes: tempTime.minutes(), seconds: tempTime.seconds() });

                    if (tempTime.days() <= 0 && tempTime.hours() <= 0 && tempTime.minutes() <= 2) {
                        setOpen(true);
                    }

                    if (!!setDisplay) {
                        if (tempTime.days() <= 0 && tempTime.hours() <= 0 && tempTime.minutes() <= 0 && tempTime.seconds() <= 0) {
                            setDisplay(false);
                        } else {
                            setDisplay(true);
                        };
                    }

                };
                getTimeUntilEvent(startDate);
            }, 1000);
            return () => { if (counter) clearInterval(counter); };
        }


    }, [startDate, paused]);

    const handleClose = () => {
        setOpen(false);
        if (setShowDialog) { setShowDialog(false); };
    };

    return (
        <>
            <div className={styles.content}>
                <Typography className={styles.title} variant="subtitle1">
                    {label ? label : "SYNK STARTS IN"}
                </Typography>
                <div className={styles.counter}>
                    {paused ? (
                        <div className={styles.paused}>
                            <p className={styles.text}>Paused</p>
                        </div>
                    ) :
                        <>
                            {days > 0 &&
                                <div className={styles.numberContainer}>
                                    <h3 className={styles.number}>{days}</h3>
                                    <p className={styles.text}>Day</p>
                                </div>}
                            <div className={styles.numberContainer}>
                                <h3 className={styles.number}>{hours <= 0 ? 0 : hours}</h3>
                                <p className={styles.text}>Hour</p>
                            </div>
                            <div className={styles.numberContainer}>
                                <h3 className={styles.number}>{minutes <= 0 ? 0 : minutes}</h3>
                                <p className={styles.text}>Min</p>
                            </div>
                            <div className={styles.numberContainer}>
                                <h3 className={styles.number}>{seconds <= 0 ? 0 : seconds}</h3>
                                <p className={styles.text}>Sec</p>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Dialog
                open={(open && showDialog)}
                onClose={handleClose}
                aria-labelledby="counter-dialog-title"
                aria-describedby="counter-dialog-description"
                fullWidth
            >
                <DialogTitle id="counter-dialog-title">Event is starting soon</DialogTitle>
                <DialogContent>
                    <DialogContentText id="counter-dialog-description">
                        Less then 2 minutes before your event begins.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Counter;