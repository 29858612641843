import { db, storage } from "firebase-app/config";
import { Badge, BadgeKey } from "interfaces";
import { getDownloadImage, LoadIcon } from "modules/ui";
import React, { useEffect, useState } from "react";
import { createGIF } from "gifshot";
import mergeImages from "merge-images";
import FallbackBlankImage from "./images/fallback-blank.png";
import { StateContext } from "context/context";
import { Dimensions, ImageLayer } from "./interfaces";
import { LinearProgress } from "@mui/material";
import { dbLogError } from "hooks/dbLogError";
import { opacityItem, setFrameLevel } from "./data";
import html2canvas from "html2canvas-objectfit-fix";

interface Props {
    badge: Badge;
    setPreview: (value: string) => void;
    preview?: string;
}

const opacityDefaults = {
    topLeft: .8,
    topRight: .3,
    bottomLeft: .1,
    bottomRight: .8
};

const frameCount = 1;

const BadgeContent = ({ badge, setPreview, preview }: Props) => {
    const [progress, setProgress] = useState<any>("");

    const { state } = StateContext();

    const getImages = async (portrait: ImageLayer) => {

        const result: { [key in BadgeKey]?: ImageLayer; } = {};

        for await (const [key, value] of Object.entries(badge)) {

            if (value.url && value.active) {

                const url = await storage.ref(value.path).getDownloadURL();

                await getDownloadImage(url).then((response) => {

                    const src = response as string;

                    switch (key) {
                        case BadgeKey.Background:
                            result[BadgeKey.Background] = { src, position: BadgeKey.Background, x: undefined, y: undefined, opacity: 1 };
                            break;
                        case BadgeKey.TopLeft:
                            result[BadgeKey.TopLeft] = { src, position: BadgeKey.TopLeft, x: 10, y: Dimensions.Top, opacity: opacityDefaults.topLeft };
                            break;
                        case BadgeKey.TopRight:
                            result[BadgeKey.TopRight] = { src, position: BadgeKey.TopRight, x: 262, y: Dimensions.Top, opacity: opacityDefaults.topRight };
                            break;
                        case BadgeKey.BottomLeft:
                            result[BadgeKey.BottomLeft] = { src, position: BadgeKey.BottomLeft, x: 10, y: 310, opacity: opacityDefaults.bottomLeft };
                            break;
                        case BadgeKey.BottomRight:
                            result[BadgeKey.BottomRight] = ({ src, position: BadgeKey.BottomRight, x: 262, y: 310, opacity: opacityDefaults.bottomRight });
                            break;
                        case BadgeKey.BottomMiddle:
                            result[BadgeKey.BottomMiddle] = { src, position: BadgeKey.BottomMiddle, x: 256, y: 406, opacity: 1 };
                            break;
                        default:
                            break;
                    }
                });
            }
        }

        createFrames(result, portrait);
    };

    const createPortrait = async (): Promise<ImageLayer> => {
        const portrait: HTMLElement | null = document.getElementById("portrait-canvas");

        if (!portrait) {
            return { src: FallbackBlankImage, x: 120, y: 120, opacity: 1 };
        }

        // important for grid placement when placed in canvas
        window.scrollTo(0, 0);
        return await html2canvas(portrait, { width: 70, height: 90, backgroundColor: "transparent", scale: 1 }).then(async function (canvas) {

            const img = canvas.toDataURL("image/png", { allowTaint: true, useCORS: true, scale: 1 });

            return await new Promise((resolve, reject) => {

                if (img) {
                    resolve(
                        {
                            src: badge.portrait?.active ? img : FallbackBlankImage,
                            x: badge.portrait?.xAxis ?? 177,
                            y: badge.portrait?.yAxis ?? 203,
                            opacity: 1
                        }
                    );
                } else {
                    reject({ src: FallbackBlankImage, x: 120, y: 120, opacity: 1 });
                }
            });
        });
    };

    const createFrames = async (images: { [key in BadgeKey]?: ImageLayer; }, innerPortrait: ImageLayer) => {
        const result: string[] = [];

        const sequence = (1 / (frameCount / 2));

        let topLeftStart = opacityDefaults.topLeft;
        let topLeftReverse = true;

        let topRightStart = opacityDefaults.topRight;
        let topRightReverse = true;

        let bottomLeftStart = opacityDefaults.bottomLeft;
        let bottomLeftReverse = true;

        let bottomRightStart = opacityDefaults.bottomRight;
        let bottomRightReverse = true;

        for await (const _ of Array.apply(null, { length: frameCount } as any)) {

            const [resultTopLeftStart, resultTopLeftReverse] = setFrameLevel(topLeftStart, topLeftReverse, sequence);
            topLeftStart = resultTopLeftStart;
            topLeftReverse = resultTopLeftReverse;

            const [resultTopRightStart, resultTopRightReverse] = setFrameLevel(topRightStart, topRightReverse, sequence);
            topRightStart = resultTopRightStart;
            topRightReverse = resultTopRightReverse;

            const [resultBottomLeftStart, resultBottomLeftReverse] = setFrameLevel(bottomLeftStart, bottomLeftReverse, sequence);
            bottomLeftStart = resultBottomLeftStart;
            bottomLeftReverse = resultBottomLeftReverse;

            const [resultBottomRightStart, resultBottomRightReverse] = setFrameLevel(bottomRightStart, bottomRightReverse, sequence);
            bottomRightStart = resultBottomRightStart;
            bottomRightReverse = resultBottomRightReverse;

            await mergeImages(
                [
                    images.background || { src: FallbackBlankImage },
                    images.topLeft ? opacityItem(images.topLeft, topLeftStart) : { src: FallbackBlankImage },
                    images.topRight ? opacityItem(images.topRight, topRightStart) : { src: FallbackBlankImage },
                    images.bottomLeft ? opacityItem(images.bottomLeft, bottomLeftStart) : { src: FallbackBlankImage },
                    images.bottomRight ? opacityItem(images.bottomRight, bottomRightStart) : { src: FallbackBlankImage },
                    images.bottomMiddle || { src: FallbackBlankImage },
                    innerPortrait || { src: FallbackBlankImage },
                ], { width: Dimensions.Width, height: Dimensions.Height, quality: 1, format: "image/png" }
            ).then(response => {
                return result.push(response);
            });
        };

        createGif(result);
    };

    const createGif = (value: string[]) => {

        // const options = {
        //     images: value,
        //     gifWidth: Dimensions.Width,
        //     gifHeight: Dimensions.Height,
        //     numWorkers: 5,
        //     frameDuration: 0.05,
        //     sampleInterval: 10,
        //     progressCallback: (e: any) => setProgress(Number(e * 100)),
        // };

        // createGIF(options, (obj: any) => {
        //     if (!obj.error) setPreview(obj.image);
        //     handleSubmit(obj.image);
        // });
        setPreview(value[0]);
        handleSubmit(value[0]);
    };

    const handleSubmit = async (imageInfo?: any) => {
        const imagePath = `users/${state.user.uid}/badges/${state.event.id}-badge`;

        try {
            const snapshot = await storage.ref(imagePath).putString(imageInfo, "data_url");
            const imageDownloadUrl = await snapshot.ref.getDownloadURL();

            await db.collection("users")
                .doc(state.user.uid).collection("badges")
                .doc(state.event.id).set({
                    url: imageDownloadUrl,
                    path: imagePath,
                });

        } catch (err: any) {
            // log error to Firestore
            await dbLogError(err, `Uploading Badge Image Issues`);

        }
    };

    useEffect(() => {
        createPortrait()
            .then((data) => {
                getImages(data);
            });
    }, []);

    return (
        <div
            style={{
                padding: 8,
                margin: "auto",
                width: "fit-content",
                minWidth: 200,
                minHeight: 150,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            {preview ? <img src={preview} alt="badge preview" style={{ maxWidth: "100%", maxHeight: 400 }} /> : <LoadIcon />}
            <div id="portrait-canvas" style={{ width: 70, height: 90, overflow: "hidden", borderRadius: 40, background: "transparent", zIndex: -1, position: "absolute" }}>
                <img src={state.picture.currentImage.url} alt="profile" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
            </div>
            {/* {progress < 100 && <LinearProgress variant="determinate" value={progress} />} */}
        </div>
    );
};

export default BadgeContent;