import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { PhoneLogin } from "../../../../../auth";
import styles from "./styles/verify-user-modal.module.scss";

interface Props {
    title: string;
    onSubmit: () => void;
    error: string;
    open: boolean;
}

const VerifyUserModal: React.FC<Props> = ({ open, title, onSubmit, error }) => (
    <Dialog open={open}>
        <DialogTitle className={styles.title}>{title}</DialogTitle>
        <DialogContent className={styles.content}>
            <Typography variant="subtitle1" >Hosted by {title}</Typography>
            <Typography className={styles.subtitle} variant="subtitle2" >Please verify your phone number in order to continue.</Typography>
            <div>
                <PhoneLogin webcamCapture onWebcamSubmit={onSubmit} />
            </div>
            <span style={{ color: "crimson" }}>
                {error && "Oops something went wrong. Please try again"}
            </span>
        </DialogContent>
        <DialogActions aria-hidden></DialogActions>
    </Dialog>
);

export default VerifyUserModal;