import React, { useState } from "react";
import { Header } from "modules/ui";
import { ImShare2 } from "react-icons/im";
import { AiFillHeart } from "react-icons/ai";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { FaTwitter, FaFacebookF } from "react-icons/fa";
import { StateContext } from "context/context";
import dataURLtoFile from "helpers/dataURLtoFile";
import { UseClickOutside } from "hooks/use-click-outside";

export default function Share() {
    const { state } = StateContext();
    const [toggle, setToggle] = useState<boolean>(false);    

    const finalImage = state.editedPicture;

    const domNode = UseClickOutside(() => {
        setToggle(false);
    });

    let _navigator: Navigator;
    _navigator = window.navigator;

    const _files: Array<any> = [];

    const handleShare = async () => {
        _files.push(dataURLtoFile(finalImage, "user-image.png"));
        if (_navigator.canShare && _navigator.canShare({ files: _files })) {
            _navigator
                .share({
                    files: _files,
                })
                .then(() => {
                })
                .catch((err: Error) => {
                    console.error("Sharing failed,", err);
                });
        } else {
            setToggle(!toggle);
        }
    };

    const handleGrid = (id: string) => {
        window.open(`https://synk-edae9.web.app/event/${id}/grid`);
    };

    return (
        <>
            <Header background="#ecf7ff" logoColor="#522CA9" showContent={true} />
            <div className="share">
                <div className="share__image">
                    <img src={finalImage.toString()} alt="final edit" />
                </div>
                <div className="share__buttons">
                    <div className="share__content">
                        <button onClick={handleShare} className="share__share">
                            <ImShare2 />
                            Share
                        </button>
                        <a
                            href={finalImage.toString()}
                            download="test.png"
                            className="share__save"
                        >
                            <AiFillHeart />
                            Save
                        </a>
                        {state.event.gridShareButton &&
                            <button onClick={() => handleGrid(state.event.id)} className="share__share">
                                <BsGrid3X3GapFill />
                                Grid
                            </button>
                        }
                    </div>
                    {state.event.donation && (
                        <button
                            onClick={() => window.open(state.event.donateUrl)}
                            className="share__donate"
                        >
                            {state.event.donateText ? state.event.donateText : "Donate"}
                        </button>
                    )}
                </div>
                <div
                    ref={domNode}
                    onClick={handleShare}
                    className={`share__socials ${toggle ? "show" : ""}`}
                >
                    {/* need to add in destination after to link are not broken */}
                    <a
                        className="share__social"
                        target="_blank"
                        rel="noreferrer"
                        href={`https://twitter.com/intent/tweet?/${"Random1"}&text=${"HELLO"}`}
                    >
                        <FaTwitter />
                    </a>
                    <span>Don't forget to save it and share it to Instagram</span>
                    <a
                        className="share__social"
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${"Random1"}`}
                    >
                        <FaFacebookF />
                    </a>
                </div>
            </div>
        </>
    );
}
