import React from "react";

export default function NumberTwoSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M0.979403 11V9.88636L4.42472 6.31676C4.79261 5.92898 5.09588 5.58925 5.33452 5.29759C5.57647 5.0026 5.7571 4.72254 5.87642 4.45739C5.99574 4.19223 6.0554 3.91051 6.0554 3.61222C6.0554 3.27415 5.97585 2.98248 5.81676 2.73722C5.65767 2.48864 5.44058 2.29806 5.16548 2.16548C4.89039 2.02959 4.58049 1.96165 4.2358 1.96165C3.87121 1.96165 3.55303 2.03622 3.28125 2.18537C3.00947 2.33452 2.80066 2.54498 2.65483 2.81676C2.509 3.08854 2.43608 3.40672 2.43608 3.77131H0.96946C0.96946 3.15151 1.11198 2.60961 1.39702 2.1456C1.68205 1.68158 2.07315 1.32197 2.57031 1.06676C3.06747 0.808238 3.63258 0.678977 4.26562 0.678977C4.9053 0.678977 5.46875 0.806581 5.95597 1.06179C6.4465 1.31368 6.82931 1.65838 7.1044 2.09588C7.3795 2.53007 7.51705 3.0206 7.51705 3.56747C7.51705 3.94531 7.44579 4.31487 7.30327 4.67614C7.16406 5.0374 6.92045 5.4401 6.57244 5.88423C6.22443 6.32505 5.74053 6.86032 5.12074 7.49006L3.0973 9.60795V9.68253H7.68111V11H0.979403Z" fill="url(#paint0_linear_101_42)" />
            <defs>
                <linearGradient id="paint0_linear_101_42" x1="7.83117" y1="16.2078" x2="2.13354" y2="15.9033" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}