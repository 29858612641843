/**
 * Takes a blob and converts it into a base64 string
 * Inspired by: https://stackoverflow.com/questions/58075409/fetching-a-list-of-urls-from-an-array-and-processing-them
 * @param blob
 */
export const toBase64 = async (blob: Blob) =>
    await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
    });
