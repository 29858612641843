import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as ROUTES from "constants/routes";
import { Button } from "@material-ui/core";
import styles from "./styles/home.module.scss";
import { StateContext } from "context/context";
import { PhoneLogin, InputPhone } from "auth";
import { SynkLogo } from "modules/ui";

declare global {
	interface Window {
		firebase: any;
	}
}

export default function Home() {
	const [number, setNumber] = useState<any>(undefined);
	const { state } = StateContext();

	return (
		<div className={styles.home}>
			<div className={styles.logo}>
				<SynkLogo style={{ fontSize: "20rem", marginBottom: "-5rem", marginTop: "-3rem" }} />
				{!state.userNumber &&
					<p className={styles.text}>
						Log in to view your
						<span> Synk </span>
						Events
					</p>
				}
			</div>

			{state.userNumber ?
				<>
					<p style={{ textAlign: "center", marginTop: "10em" }}>Welcome Back! Proceed to Events</p>
					<Button
						fullWidth
						className={styles.browse}
						variant="contained"
						color="primary"
						component={Link}
						to={ROUTES.EVENTS}
					>
						Browse Public Events
					</Button>
				</>
				:
				true ?
					<PhoneLogin /> :
					// TODO Set this Up for email login later on in auth folder component
					<>
						<form action="" className={styles.login}>
							<InputPhone number={number} setNumber={setNumber} />
							<button
								style={{ opacity: isValidPhoneNumber(number || "") ? "1" : ".5" }}
								disabled={!isValidPhoneNumber(number || "")}
								className="home__login-btn">Log in
							</button>
						</form>
						<span className={styles.divider}>or</span>
					</>
			}
		</div>
	);
};
