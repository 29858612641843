import { toBase64 } from "./toBaseSixtyFour";

/**
 * Takes a download url and uses fetch api to download as a blob
 * Then calls another function to convert this into a base64 string
 * Inspired by: https://stackoverflow.com/questions/58075409/fetching-a-list-of-urls-from-an-array-and-processing-them
 * @param url
 */
export const getImageAsBase64 = async (url: RequestInfo) =>
    await fetch(url)
        .then((resp) => resp.blob())
        .then(toBase64);
