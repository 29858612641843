enum GridType {
    Portrait = "portrait",
    Middle = "middle",
    TwentyFive = "twentyFive",
    Hundred = "hundred",
    FeatureTwentyFive = "featureTwentyFive",
    FeatureTwentyFiveCenter = "featureTwentyFiveCenter",
    FeatureHundred = "featureHundred",
    FeatureHundredCenter = "featureHundredCenter",
}

export default GridType;
