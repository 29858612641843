import * as React from "react";

function SvgSynkLogo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			id="synk-logo_svg__Logo_x5F_04"
			xmlns="http://www.w3.org/2000/svg"
			x={0}
			y={0}
			viewBox="0 0 1000 500"
			xmlSpace="preserve"
			width="1em"
			height="1em"
			{...props}
		>
			<style>{".synk-logo_svg__st0{fill:#fff}"}</style>
			<path
				className="synk-logo_svg__st0"
				d="M470.28 221.68h-20.25v-8.38c0-.61-.46-1.07-.91-1.07h-67.31c-.61 0-1.07.46-1.07 1.07v22.54c0 .61.46 1.07 1.07 1.07h67.31c11.57 0 21.17 9.44 21.17 21.17v22.54c0 11.73-9.59 21.17-21.17 21.17h-67.31c-11.73 0-21.17-9.44-21.17-21.17v-8.38h20.1v8.38c0 .61.46 1.07 1.07 1.07h67.31c.46 0 .91-.46.91-1.07v-22.54c0-.61-.46-1.07-.91-1.07h-67.31c-11.73 0-21.17-9.44-21.17-21.17V213.3c0-11.73 9.44-21.17 21.17-21.17h67.31c11.57 0 21.17 9.44 21.17 21.17v8.38zM617.39 192.13l-50.71 68.68v40.96h-20.1v-40.96c-15.99-21.78-34.87-46.9-50.71-68.68h23.91l36.85 46.6 36.7-46.6h24.06zM729.16 192.13h20.25v109.64h-21.93l-67.61-80.41v80.41h-20.1V192.13h21.93l67.46 80.41v-80.41zM895.15 192.13v5.33l-41.42 49.49 41.42 49.49v5.33h-22.08L835.61 257h-25.89v44.77h-20.25V192.13h20.25v44.77h25.89c12.18-14.62 25.43-30.15 37.46-44.77h22.08z"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_1_"
				gradientUnits="userSpaceOnUse"
				x1={360.639}
				y1={246.956}
				x2={895.151}
				y2={246.956}
			>
				<stop offset={0} stopColor="#67b1b4" />
				<stop offset={0.203} stopColor="#65acb3" />
				<stop offset={0.443} stopColor="#5d9eaf" />
				<stop offset={0.701} stopColor="#5286a8" />
				<stop offset={0.97} stopColor="#41659f" />
				<stop offset={1} stopColor="#3f619e" />
			</linearGradient>
			<path
				d="M470.28 221.68h-20.25v-8.38c0-.61-.46-1.07-.91-1.07h-67.31c-.61 0-1.07.46-1.07 1.07v22.54c0 .61.46 1.07 1.07 1.07h67.31c11.57 0 21.17 9.44 21.17 21.17v22.54c0 11.73-9.59 21.17-21.17 21.17h-67.31c-11.73 0-21.17-9.44-21.17-21.17v-8.38h20.1v8.38c0 .61.46 1.07 1.07 1.07h67.31c.46 0 .91-.46.91-1.07v-22.54c0-.61-.46-1.07-.91-1.07h-67.31c-11.73 0-21.17-9.44-21.17-21.17V213.3c0-11.73 9.44-21.17 21.17-21.17h67.31c11.57 0 21.17 9.44 21.17 21.17v8.38z"
				fill="url(#synk-logo_svg__SVGID_1_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000081641914355195233510000001452839810333366941_"
				gradientUnits="userSpaceOnUse"
				x1={360.639}
				y1={246.956}
				x2={895.151}
				y2={246.956}
			>
				<stop offset={0} stopColor="#67b1b4" />
				<stop offset={0.203} stopColor="#65acb3" />
				<stop offset={0.443} stopColor="#5d9eaf" />
				<stop offset={0.701} stopColor="#5286a8" />
				<stop offset={0.97} stopColor="#41659f" />
				<stop offset={1} stopColor="#3f619e" />
			</linearGradient>
			<path
				d="M617.39 192.13l-50.71 68.68v40.96h-20.1v-40.96c-15.99-21.78-34.87-46.9-50.71-68.68h23.91l36.85 46.6 36.7-46.6h24.06z"
				fill="url(#synk-logo_svg__SVGID_00000081641914355195233510000001452839810333366941_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000165947993240934375110000014829766960759680650_"
				gradientUnits="userSpaceOnUse"
				x1={360.639}
				y1={246.956}
				x2={895.151}
				y2={246.956}
			>
				<stop offset={0} stopColor="#67b1b4" />
				<stop offset={0.203} stopColor="#65acb3" />
				<stop offset={0.443} stopColor="#5d9eaf" />
				<stop offset={0.701} stopColor="#5286a8" />
				<stop offset={0.97} stopColor="#41659f" />
				<stop offset={1} stopColor="#3f619e" />
			</linearGradient>
			<path
				d="M729.16 192.13h20.25v109.64h-21.93l-67.61-80.41v80.41h-20.1V192.13h21.93l67.46 80.41v-80.41z"
				fill="url(#synk-logo_svg__SVGID_00000165947993240934375110000014829766960759680650_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000030449457128331064230000015986424837872214433_"
				gradientUnits="userSpaceOnUse"
				x1={360.639}
				y1={246.956}
				x2={895.151}
				y2={246.956}
			>
				<stop offset={0} stopColor="#67b1b4" />
				<stop offset={0.203} stopColor="#65acb3" />
				<stop offset={0.443} stopColor="#5d9eaf" />
				<stop offset={0.701} stopColor="#5286a8" />
				<stop offset={0.97} stopColor="#41659f" />
				<stop offset={1} stopColor="#3f619e" />
			</linearGradient>
			<path
				d="M895.15 192.13v5.33l-41.42 49.49 41.42 49.49v5.33h-22.08L835.61 257h-25.89v44.77h-20.25V192.13h20.25v44.77h25.89c12.18-14.62 25.43-30.15 37.46-44.77h22.08z"
				fill="url(#synk-logo_svg__SVGID_00000030449457128331064230000015986424837872214433_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000040566911975910059400000000694201255811207851_"
				gradientUnits="userSpaceOnUse"
				x1={-8218.726}
				y1={3907.616}
				x2={-8041.792}
				y2={3907.616}
				gradientTransform="rotate(-87.759 -5891.384 -2270.086)"
			>
				<stop offset={0} stopColor="#80eddb" />
				<stop offset={1} stopColor="#538498" />
			</linearGradient>
			<path
				d="M282.11 226.13c18.87-9.02 30.16-27.49 30.93-47.03.37-9.46-1.73-19.17-6.64-28.19-13.47-24.74-44.51-35.11-70.18-23.51-13.05 5.9-22.59 16.26-27.71 28.44-5.2 12.36-15.08 22.18-27.18 27.97-11.98 5.73-25.6 7.08-38.46 3.76-12.47-3.22-26.15-2.06-38.58 4.38-17.76 9.21-28.51 27.42-29.25 46.49-.3 7.56.98 15.26 4.02 22.58 12.04 29.07 45.67 41.48 73.81 28.35 11.72-5.47 20.59-14.96 25.73-25.93 5.69-12.15 14.96-22.26 27.06-28.04l3.16-1.51c11.81-5.65 25.15-6.64 37.93-3.76 11.49 2.58 23.9 1.48 35.36-4z"
				fill="url(#synk-logo_svg__SVGID_00000040566911975910059400000000694201255811207851_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000149341841973750715140000010619047786200105650_"
				gradientUnits="userSpaceOnUse"
				x1={-8169.203}
				y1={3907.616}
				x2={-8041.792}
				y2={3907.616}
				gradientTransform="rotate(-87.759 -5891.384 -2270.086)"
			>
				<stop offset={0} stopColor="#80eddb" />
				<stop offset={1} stopColor="#538498" />
			</linearGradient>
			<path
				d="M208.52 155.83c-5.2 12.36-15.08 22.18-27.18 27.97-11.98 5.73-25.6 7.08-38.46 3.76-12.47-3.22-26.15-2.06-38.58 4.38-17.76 9.21-28.51 27.42-29.25 46.49-.06 1.49-.04 2.98.03 4.48.75-6.03 2.51-12 5.38-17.65 14.27-28.04 48.92-38.05 75.8-22.51 11.31 6.54 19.36 16.52 23.62 27.86 2.3 6.11 5.5 11.79 9.5 16.81 4.62-4.94 10.07-9.06 16.28-12.03l3.16-1.51c11.81-5.65 25.15-6.64 37.93-3.76 11.5 2.59 23.91 1.49 35.37-3.99 18.87-9.02 30.16-27.49 30.93-47.03.37-9.46-1.73-19.17-6.64-28.19-13.47-24.74-44.51-35.11-70.18-23.51-13.06 5.9-22.59 16.26-27.71 28.43z"
				fill="url(#synk-logo_svg__SVGID_00000149341841973750715140000010619047786200105650_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000028326855449985446170000010471884800254769058_"
				gradientUnits="userSpaceOnUse"
				x1={-8223.102}
				y1={3847.525}
				x2={-8122.897}
				y2={3847.525}
				gradientTransform="matrix(.1204 -1.0784 .9938 .111 -2706.88 -9000.281)"
			>
				<stop offset={0} stopColor="#80eddb" />
				<stop offset={0.137} stopColor="#7de8da" />
				<stop offset={0.299} stopColor="#74dad5" />
				<stop offset={0.474} stopColor="#66c2ce" />
				<stop offset={0.657} stopColor="#51a1c4" />
				<stop offset={0.845} stopColor="#3877b8" />
				<stop offset={1} stopColor="#1f4eac" />
			</linearGradient>
			<path
				d="M159.82 196.7c-6.13-4.48-8.63-6.48-16.94-9.15-12.48-3.23-26.1-2.09-38.58 4.38-17.72 9.22-28.55 27.49-29.25 46.49-.3 7.52.98 15.22 4.02 22.58 11.96 29.01 45.69 41.46 73.81 28.35 11.64-5.42 20.61-15.09 25.73-25.93 5.8-12.07 10.25-14.81 10.78-16.04-3.58-5.7-6.46-12.15-8.26-18.92-3.34-12.55-10.56-23.91-21.31-31.76z"
				fill="url(#synk-logo_svg__SVGID_00000028326855449985446170000010471884800254769058_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000060746837951007945650000003205605056295528851_"
				gradientUnits="userSpaceOnUse"
				x1={-8294.553}
				y1={3970.2}
				x2={-8041.789}
				y2={3970.2}
				gradientTransform="rotate(-87.759 -5891.384 -2270.086)"
			>
				<stop offset={0} stopColor="#80eddb" />
				<stop offset={0.137} stopColor="#7de8da" />
				<stop offset={0.299} stopColor="#74dad5" />
				<stop offset={0.474} stopColor="#66c2ce" />
				<stop offset={0.657} stopColor="#51a1c4" />
				<stop offset={0.845} stopColor="#3877b8" />
				<stop offset={1} stopColor="#1f4eac" />
			</linearGradient>
			<path
				d="M204.25 173.18c-1.01 14.21 3.5 27.43 11.66 37.69 8.13 10.22 11.88 23.24 11.37 36.29-.52 13.4-5.63 26.36-14.92 36.03-9.15 9.52-14.89 22.38-15.15 36.7-.5 28.16 21.32 52.56 49.37 55.14 31.31 2.88 58.25-21.06 59.46-52.08.5-12.69-3.4-24.53-10.33-34.06-7.7-10.59-12.07-23.23-11.56-36.32l.14-3.5c.52-13.4 6.14-25.92 15.04-35.95 8.04-9.06 13.12-20.84 13.63-33.89 1.21-31.03-23.79-57.01-55.26-56.56-27.93.39-51.46 22.65-53.45 50.51z"
				opacity={0.5}
				fill="url(#synk-logo_svg__SVGID_00000060746837951007945650000003205605056295528851_)"
			/>
			<linearGradient
				id="synk-logo_svg__SVGID_00000043445823997202184610000016020969043843767681_"
				gradientUnits="userSpaceOnUse"
				x1={-8294.554}
				y1={3944.481}
				x2={-8174.526}
				y2={3944.481}
				gradientTransform="rotate(-87.759 -5891.384 -2270.086)"
			>
				<stop offset={0} stopColor="#80eddb" />
				<stop offset={1} stopColor="#538498" />
			</linearGradient>
			<path
				d="M156.46 296.88c-2.93 1.4-5.93 2.57-8.97 3.51 9.73.03 19.38 2.8 27.91 7.73 11.61 6.71 20.69 17.27 24.91 30 4.15 12.53 12.85 23.61 25.4 30.51 24.68 13.57 56.44 5.65 71.8-17.96 17.15-26.36 8.53-61.35-18.34-76.89-11-6.36-23.29-8.43-34.95-6.74-12.96 1.87-26.18-.15-37.52-6.71l-3.03-1.75a53.124 53.124 0 01-7.88-5.59c-3.37 3.69-6.26 7.98-8.54 12.86-6.36 13.55-17.29 24.57-30.79 31.03z"
				fill="url(#synk-logo_svg__SVGID_00000043445823997202184610000016020969043843767681_)"
			/>
			<g>
				<path
					className="synk-logo_svg__st0"
					d="M222.52 176.41l16.52-28.33H220.1a47.817 47.817 0 00-9.53 28.71c0 5.56.95 10.88 2.69 15.85l9.39-16-.13-.23zM270.8 207.31h-27.51l10.02 17.19c1.73.19 3.48.29 5.26.29 14.92 0 28.24-6.81 37.05-17.48H270.8zM246.77 145.51h26.72l-9.6-16.42c-1.75-.19-3.52-.3-5.32-.3-14.55 0-27.59 6.49-36.39 16.72h24.59zM290.03 184.28l-11.93 20.45h19.49a47.768 47.768 0 008.99-27.95c0-5.65-.98-11.07-2.78-16.11l-13.49 23.76-.28-.15zM276.6 145.51l16.65 28.54 9.33-16.43c-6.3-14.44-19.49-25.16-35.42-28.04l9.32 15.94h.12zM236.71 200.73l-12.57-21.54-9.69 16.52c6.26 14.59 19.55 25.44 35.6 28.32l-13.52-23.19.18-.11z"
				/>
			</g>
			<g>
				<path
					className="synk-logo_svg__st0"
					d="M92.55 240.36l16.52-28.33H90.13a47.817 47.817 0 00-9.53 28.71c0 5.56.95 10.88 2.69 15.85l9.39-16-.13-.23zM140.83 271.26h-27.51l10.02 17.19c1.73.19 3.48.29 5.26.29 14.92 0 28.24-6.81 37.05-17.48h-24.82zM116.8 209.46h26.72l-9.6-16.42c-1.75-.19-3.52-.3-5.32-.3-14.55 0-27.59 6.49-36.39 16.72h24.59zM160.06 248.23l-11.93 20.45h19.49a47.768 47.768 0 008.99-27.95c0-5.65-.98-11.07-2.78-16.11l-13.49 23.76-.28-.15zM146.63 209.46L163.28 238l9.33-16.43c-6.3-14.44-19.49-25.16-35.42-28.04l9.32 15.94h.12zM106.74 264.68l-12.57-21.54-9.69 16.52c6.26 14.59 19.55 25.44 35.6 28.32l-13.52-23.19.18-.11z"
				/>
			</g>
			<g>
				<path
					className="synk-logo_svg__st0"
					d="M215.59 320.48l16.52-28.33h-18.94a47.817 47.817 0 00-9.53 28.71c0 5.56.95 10.88 2.69 15.85l9.39-16-.13-.23zM263.88 351.39h-27.51l10.02 17.19c1.73.19 3.48.29 5.26.29 14.92 0 28.24-6.81 37.05-17.48h-24.82zM239.84 289.58h26.72l-9.6-16.42c-1.75-.19-3.52-.3-5.32-.3-14.55 0-27.59 6.49-36.39 16.72h24.59zM283.11 328.36l-11.93 20.45h19.49a47.768 47.768 0 008.99-27.95c0-5.65-.98-11.07-2.78-16.11l-13.49 23.76-.28-.15zM269.67 289.58l16.65 28.54 9.33-16.43c-6.3-14.44-19.49-25.16-35.42-28.04l9.32 15.94h.12zM229.78 344.81l-12.57-21.54-9.69 16.52c6.26 14.59 19.55 25.44 35.6 28.32l-13.52-23.19.18-.11z"
				/>
			</g>
		</svg>
	);
}

export default SvgSynkLogo;