import { Typography, Box, Dialog, DialogContent, Grid } from "@material-ui/core";
import firebase from "firebase";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { AiFillCheckCircle } from "react-icons/ai";
import { useParams, useHistory } from "react-router-dom";
import { PhoneLogin } from "auth";
import { HeaderTag } from "modules/event/components";
import { StateContext } from "context/context";
import { db, functions } from "firebase-app/config";
import { dbLogError } from "hooks/dbLogError";
import { CharitySelection, TickerBlob } from "interfaces";
import * as ROUTES from "constants/routes";
import styles from "./styles/event.module.scss";
import { Steps } from "./interfaces";
import { AppAction } from "reducers/reducer-action";
import { GridType } from "../edit-photo/grids/interfaces";
import { BoxShadowWrapper, RaffleSelection, Thermometer, Counter, ScreenTicker, ScreenTickerModal, Button, LoadIcon, Header, Privacy, CameraPerm } from "modules/ui";
import PaymentSelection from "./payment-selection/payment-selection";

export default function Event() {
    const [loading, setLoading] = useState<boolean>(true);
    const [showDialog, setShowDialog] = useState<boolean>(true);
    const [paidIds, setPaidIds] = useState<string[]>([]);
    const [productsId, setProductsId] = useState<string[]>([]);
    const [step, setStep] = useState<number>(0);
    const [paidStatusLoaded, setPaidStatusLoaded] = useState<boolean>(false);
    const [userHasPaid, setUserHasPaid] = useState<boolean>(false);
    const [signUps, setSignUps] = useState<number>(0);

    const { state, dispatch } = StateContext();

    const { event, userNumber, user, privacyPermission } = state;

    let { id } = useParams<{ id: string; }>();

    const history = useHistory();

    const raffleSelectionExist = sessionStorage.getItem(`charitySelection-${event.id}`);

    // On initial render grabs current event
    useEffect(() => {
        if (raffleSelectionExist) {
            setStep(Steps.Payment);
        }

        function getEvent() {

            try {
                const doc = db.collection("events").doc(id);

                doc.onSnapshot(
                    (docSnapshot) => {
                        if (docSnapshot.exists) {
                            dispatch({
                                type: AppAction.SetEvent,
                                payload: {
                                    ...state,
                                    event: docSnapshot.data() as any,
                                    selectedGrid: docSnapshot.data()?.gridDefault ?? GridType.Portrait
                                },
                            });
                        }

                    },
                    (err) => {
                        console.error(`Encountered error: ${err}`);
                        dbLogError(err, "getEvent via event.tsx");
                    }
                );
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        getEvent();

        if (user) {
            const userSignedUp = firebase.database().ref(`events/${id}/signUps/${user.uid}`).get();

            userSignedUp.then((snapshot) => {
                const data = snapshot.val();

                if (!data) {

                    if (userNumber) {

                        const joinEvent = functions.httpsCallable("joinEvent");
                        joinEvent({ id: event.id, title: event.title }).catch((err) =>
                            console.error(err)
                        );
                    } else {
                        localStorage.removeItem("phoneNumber");
                        localStorage.removeItem("synk-edae9-userData");
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        // Once counter hits 0 sends you to the next page
        const counter = setInterval(() => {
            const getTimeUntilEvent = (time: any) => {
                const today = new Date();
                const secondsTillEvent = time.seconds - Math.floor(today.getTime() / 1000);

                if (privacyPermission && (secondsTillEvent <= 12) && !event.datePaused) {

                    if ((event.isPaidEvent && userHasPaid) || !event.isPaidEvent) {
                        history.push(ROUTES.PHOTO);
                    } else {
                        history.push(ROUTES.EVENTS);
                    }
                }
            };

            getTimeUntilEvent(event.startDate);
        }, 1000);

        return () => clearInterval(counter);
    }, [event.startDate, userHasPaid]);


    const getMedia = async () => {
        await navigator.mediaDevices
            .getUserMedia({ audio: false, video: true })
            .catch((err: any) => {
                if (err) {
                    console.log(err, "error");
                }
            });
    };

    useEffect(() => {

        if (userNumber) {

            const payment = db.collection("customers").doc(state.user?.uid).collection("payments").where("status", "==", "succeeded").get();

            db.collection("products")
                .where("active", "==", true)
                .where("metadata.eventId", "==", event.id)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (productDoc: any) {
                        const priceSnap = await productDoc.ref.collection("prices").get();

                        await priceSnap.docs.forEach((price: any) => {
                            setProductsId(prevState => [...prevState, price.id,]);
                        });


                    });
                })
                .catch((error) => error.console.log(error));

            payment.then(async (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const payment = doc.data();

                    setPaidIds((prevState: any) => [...prevState, payment.items[0].price.id]);
                });
            });
        }

        if (event.id && isMobile && !event.isPaidEvent) {
            getMedia();
        }

        getTotalSignUps();
    }, []);

    useEffect(() => {
        const userHasPaid = paidIds.filter(element => productsId.includes(element));

        if (userHasPaid.length > 0) {
            setUserHasPaid(true);

            // set charity and add into array after user has confirmed payment for event
            const charitySelection = sessionStorage.getItem(`charitySelection-${event.id}`);

            if (charitySelection) {
                try {
                    const ref = db.collection("events").doc(event.id);

                    const charityPhone: CharitySelection = {
                        charity: charitySelection,
                        phone: Number(userNumber)
                    };

                    ref.update({
                        selectedCharities: firebase.firestore.FieldValue.arrayUnion(charityPhone)
                    });

                } catch (error) {
                    console.log(error);
                }
            }
            // set user charity selection here
            if (isMobile) {
                getMedia();
            }
        } else {
            setPaidStatusLoaded(true);
        }

    }, [paidIds, productsId, event.id]);

    const ticker: TickerBlob = {
        tickerArray: event.ticker ?? [],
        display: event.tickerDisplay,
        description: event.tickerDescription,
        buyerDescription: event.tickerBuyerDescription,
        speed: event.tickerSpeed
    };

    const handleNextStep = (selection: string) => {
        setStep(Steps.Payment);
        sessionStorage.setItem(`charitySelection-${event.id}`, selection);
    };

    const handleEnterEvent = () => {
        if (event.photo?.picNow !== undefined && event.photo?.picNow) {
            if (privacyPermission && !event.datePaused) {

                if ((event.isPaidEvent && userHasPaid) || !event.isPaidEvent) {
                    history.push(ROUTES.PHOTO);
                } else {
                    history.push(ROUTES.EVENTS);
                }
            }
        }
    };

    const handleClose = () => {
        history.goBack();
    };

    const getTotalSignUps = () => {
        const totalSignUp = firebase.database().ref(`events/${event.id}/summary/numSignUps`).get();

        totalSignUp.then((data) => setSignUps(data.val()));
    };

    let total = 0;

    if (event?.total)
        total = (event.total?.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0) / 100);

    return (
        <>
            <Header background="#ecf7ff" logoColor="#522CA9" showContent={true} />
            {loading ? (
                <div style={{ height: "100vh", width: "100%" }}>
                    <LoadIcon />
                </div>
            ) : (
                <div className={styles.container}>
                    {!privacyPermission && ((event.isPaidEvent && userHasPaid) || !event.isPaidEvent) && <Privacy />}
                    <div className={styles.content}>
                        {(event.banner?.active && event.banner?.text) && <HeaderTag label={event.banner?.text} />}
                        {event.bgImage !== undefined &&
                            <div className={styles.image}>
                                <img src={event.bgImage.url} alt="" />
                            </div>
                        }
                        <div>
                            <Typography className={styles.title} variant="h5">{event.title}</Typography>
                        </div>

                        <Counter
                            startDate={event.startDate}
                            paused={event.datePaused}
                            showDialog={showDialog}
                            setShowDialog={setShowDialog}
                            label={event.photo?.text !== undefined ? event.photo?.text : ""}
                        />
                        {userNumber &&
                            ((event.tickerDisplay && (!event.isPaidEvent || userHasPaid)) ||
                                (event.tickerDisplay && event.isPaidEvent && userHasPaid)) &&
                            <ScreenTicker isPaidEvent={event.isPaidEvent} ticker={ticker} />
                        }

                        {!userNumber ?
                            <Box>
                                <PhoneLogin />
                            </Box> :
                            <Box style={{ marginBottom: -30 }}>
                                <Button
                                    style={{ marginLeft: -30, width: "calc(100% + 60px)", pointerEvents: event.photo?.picNow !== undefined && event.photo?.picNow ? "initial" : "none" }}
                                    color="primary"
                                    btnType="primary"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleEnterEvent}
                                >
                                    {event.photo?.picNow !== undefined && event.photo?.picNow ? "Click Here To Take Your Pic Now" :
                                        <><AiFillCheckCircle style={{ fontSize: "1.4rem", marginRight: 6 }} /> Success! You have Joined!</>
                                    }
                                </Button>
                            </Box>
                        }
                    </div>

                    {!userNumber &&
                        ((event.tickerDisplay && (!event.isPaidEvent || userHasPaid)) ||
                            (event.tickerDisplay && event.isPaidEvent && userHasPaid)) &&
                        <ScreenTicker isPaidEvent={event.isPaidEvent} ticker={ticker} />
                    }

                    <div className={styles.information}>
                        {(event.tallies?.total?.active || event.tallies?.participants?.active) &&
                            <BoxShadowWrapper style={{ marginBottom: 24 }}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ textAlign: "center" }}>Total's So far...</Typography>
                                    </Grid>
                                    {event.tallies?.participants?.active &&
                                        <Grid item>
                                            <Thermometer
                                                tally={event.tallies?.participants}
                                                currentNumber={signUps}
                                                tickerLength={event.ticker?.length || 0}
                                                label="Participants"
                                                icon="participants"
                                                positionLeft
                                                color="green"
                                            />
                                        </Grid>
                                    }
                                    {event.tallies?.total?.active &&
                                        <Grid item>
                                            <Thermometer
                                                tally={event.tallies?.total}
                                                currentNumber={total}
                                                label="Donations"
                                                icon="total"
                                                color="yellow"
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </BoxShadowWrapper>
                        }
                        <Typography variant="h6">About The Event</Typography>
                        <Typography variant="body1">{event.description}</Typography>
                        {event?.postImageSend && (
                            <p>This Event will send a Photo your phone</p>
                        )}
                    </div>

                    {!isMobile && ((event.isPaidEvent && userHasPaid) || !event.isPaidEvent) && <CameraPerm />}

                    <Dialog className={styles.modal} onClose={handleClose} open={!!(event.raffle?.active && paidStatusLoaded && (step === Steps.Charity && event.isPaidEvent && userNumber && !userHasPaid))} fullWidth>
                        <DialogContent style={{ height: "fit-content", marginBottom: 16 }}>
                            <RaffleSelection modalView title={event.title} raffle={event.raffle} onFinish={handleNextStep} />
                        </DialogContent>
                    </Dialog>

                    {(step === Steps.Payment || !event.raffle?.active) && userNumber &&
                        <PaymentSelection
                            open={!!(event.isPaidEvent && !userHasPaid && paidStatusLoaded)}
                            setOpen={() => { }}
                            eventBackgroundImage={event.bgImage?.url}
                            eventDescription={event.description}
                            eventTitle={event.title}
                            ticker={ticker}
                        />
                    }
                    {((event.isPaidEvent && userHasPaid && user)) && <ScreenTickerModal />}
                </div>
            )}
        </>
    );
};
