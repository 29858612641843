export const HOME = "/";
export const EVENTS = "/events";
export const EVENT = "/event";
export const PHOTO = "/photo";
export const EDIT_PHOTO = "/edit-photo";
export const SHARE = "/share";
export const GRID_VIEW = "/grid";
export const DYNAMIC_VIEW = "/dynamic";
export const FAQ = "/faq";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const CONTACT_AND_SUPPORT = "/contact-and-support";
export const MASSIVE = "/massive";