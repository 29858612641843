import React from "react";
import PropTypes from "prop-types";


interface Props {
	color: string;
}
const Logo: React.FC<Props> = ({ color }) => {
	return (
		<svg width="1em" height="1em" viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M6.89412 4.83836C4.74346 5.62429 3.63712 8.00487 4.42305 10.1555C5.20898 12.3062 7.58955 13.4125 9.74021 12.6266L22.9802 7.78824L20.1341 0L6.89412 4.83836ZM19.0861 19.1616C21.2368 18.3757 22.3431 15.9951 21.5572 13.8445C20.7712 11.6938 18.3907 10.5875 16.24 11.3734L3 16.2118L5.84609 24L19.0861 19.1616Z" />
		</svg>
	);
};

Logo.defaultProps = {
	color: "#522CA9"
};

export default Logo;