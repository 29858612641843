import { ThemeOptions } from "@material-ui/core/styles/createTheme";

export const theme = {
    palette: {
        primary: {
            main: "#522CA9",
        },
    },
    typography: {
        button: {
            fontFamily: '"Montserrat", "sans-serif"',
            fontWeight: 600,
            letterSpacing: ".5px",
            fontSize: "1rem",
            paddingBlock: "1em",
            textTransform: "capitalize",
            lineHeight: "normal",            
        },
        
    },
    shape: {
        borderRadius: 10,
    },
} as ThemeOptions;
