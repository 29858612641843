import * as ROUTES from "constants/routes";
import { MenuItem } from "../interfaces";

const menuArray: MenuItem[] = [
    {
        label: "Events",
        route: ROUTES.EVENTS,
    },
    {
        label: "FAQ",
        route: ROUTES.FAQ,
    },
    {
        label: "Terms and Conditions",
        route: ROUTES.TERMS_AND_CONDITIONS,
    },
    {
        label: "Contact and Support",
        route: ROUTES.CONTACT_AND_SUPPORT,
    },
];

export default menuArray;
