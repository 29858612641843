const mobile = {
    height: 442,
    width: 343,
    facingMode: "user",
};

const desktop = {
    video: {
        height: 603,
        width: 468,
    },
    forceScreenshotSourceSize: true,
    screenshotQuality: 1,
    imageSmoothing: false,
    facingMode: "user",
};

const videoConstraints = {
    mobile,
    desktop,
};

export default videoConstraints;
