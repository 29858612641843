import styles from "./styles/navigation.module.scss";
import { EventDetailModal } from "./modals";
import { BadgeCollection } from "modules/ui";

interface Props { }

const Navigation: React.FC<Props> = () => (
    <nav className={styles.navigation}>
        <ul>
            <li>
                <EventDetailModal />
            </li>
            <li>
                <BadgeCollection />
            </li>
        </ul>
    </nav>
);

export default Navigation;