import React from "react";

export default function RotationRightSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15 6.66669C16.3807 6.66669 17.5 5.5474 17.5 4.16669C17.5 2.78598 16.3807 1.66669 15 1.66669C13.6193 1.66669 12.5 2.78598 12.5 4.16669C12.5 5.5474 13.6193 6.66669 15 6.66669Z" stroke="url(#paint0_linear_538_2614)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z" stroke="url(#paint1_linear_538_2614)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 18.3333C16.3807 18.3333 17.5 17.214 17.5 15.8333C17.5 14.4526 16.3807 13.3333 15 13.3333C13.6193 13.3333 12.5 14.4526 12.5 15.8333C12.5 17.214 13.6193 18.3333 15 18.3333Z" stroke="url(#paint2_linear_538_2614)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.15845 11.2584L12.8501 14.575" stroke="url(#paint3_linear_538_2614)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8418 5.42499L7.15845 8.74165" stroke="url(#paint4_linear_538_2614)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_538_2614" x1="16.8506" y1="7.00219" x2="13.7121" y2="6.66669" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_538_2614" x1="6.85065" y1="12.8355" x2="3.71212" y2="12.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_538_2614" x1="16.8506" y1="18.6688" x2="13.7121" y2="18.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
                <linearGradient id="paint3_linear_538_2614" x1="12.1109" y1="14.7976" x2="8.61506" y2="14.1563" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
                <linearGradient id="paint4_linear_538_2614" x1="12.1037" y1="8.9642" x2="8.6126" y2="8.32472" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6584" />
                    <stop offset="1" stopColor="#6C63FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}