import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { Raffle } from "interfaces";
import { BoxShadowWrapper, Button } from "..";
import styles from "./styles/raffle-selection.module.scss";

interface Props {
    raffle: Raffle;
    title: string;
    onFinish: (selection: string) => void;
    modalView?: boolean;
    eventId?: string;
}

const RaffleSelection: React.FC<Props> = ({ raffle, title, onFinish, modalView }) => {
    const [selection, setSelection] = useState<string>("");
    const [input, setInput] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setInput(e.target.value);
        setSelection("");
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSelection(e.target.value);
        setInput("");
    };

    return (
        <Grid container spacing={3}>
            {modalView &&
                <Grid item xs={12}>
                    <Typography variant="h5">{title}</Typography>
                </Grid>
            }
            {raffle?.description &&
                <Grid item xs={12}>
                    <div className={styles.description}>
                        {raffle?.description}
                    </div>
                </Grid>
            }
            <Grid item xs={12}>
                {raffle?.buttonText &&
                    <Button fullWidth btnType="primary" variant="contained">
                        <AiFillCheckCircle style={{ fontSize: "1.4rem", marginRight: 6 }} />
                        {raffle?.buttonText}
                    </Button>
                }
            </Grid>
            <Grid item xs={12}>
                <BoxShadowWrapper>
                    <TextField id="raffle-text-field" value={input} onChange={handleInputChange} fullWidth placeholder={raffle?.charityPlaceholder} />
                </BoxShadowWrapper>
            </Grid>
            {raffle !== undefined && raffle?.charities?.length > 0 &&
                <Grid item xs={12}>
                    <BoxShadowWrapper>
                        <Typography variant="h5" style={{ fontWeight: 600, textAlign: "center" }}>Or Choose One Of These</Typography>
                        <RadioGroup
                            value={selection}
                            onChange={handleRadioChange}
                            className={styles.charities}
                        >
                            {raffle?.charities?.map((charity, i) => (
                                <FormControlLabel value={charity} key={i} label={charity} control={<Radio />} />
                            ))}
                        </RadioGroup>
                    </BoxShadowWrapper>
                </Grid>
            }
            <Grid item xs={12}>
                <Button disabled={!(selection || input)} onClick={() => onFinish(input || selection)} variant="contained" btnType="primary" fullWidth>Done</Button>
            </Grid>
        </Grid>
    );
};

export default RaffleSelection;